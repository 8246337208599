import { useEffect, useState } from 'react';
import { ExpandablePanel } from './ExpandablePanel';
import { InsightsProvider } from './InsightsProvider';

export function Insights() {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  useEffect(() => {
    const onInsightsOpen = () => setIsPanelOpen(true);
    document.addEventListener('insights:opened', onInsightsOpen);
    return () => document.removeEventListener('insights:opened', onInsightsOpen);
  }, []);

  const onClose = ({ isExpanded }: { isExpanded: boolean }) => {
    document.dispatchEvent(new CustomEvent('insights:closed', { detail: { isExpanded } }));
    setIsPanelOpen(false);
  };

  return (
    <InsightsProvider>
      <ExpandablePanel isOpen={isPanelOpen} onClose={onClose} />
    </InsightsProvider>
  );
}

export function openInsights(documentId: string) {
  document.dispatchEvent(new CustomEvent('insights:opened', { detail: { documentId } }));
}

export function useOnInsightsClose(callback: (isExpanded: boolean) => void) {
  useEffect(() => {
    document.addEventListener('insights:closed', (e) => callback(e.detail.isExpanded));
    return () => document.removeEventListener('insights:closed', (e) => callback(e.detail.isExpanded));
  }, []);
}
