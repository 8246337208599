import React, { MouseEvent, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Resizable, ResizeCallback } from 're-resizable';
import { DraggableCore, DraggableData, DraggableEvent } from 'react-draggable';

import { availableResizeHandle } from '../consts';
import ContentClipper from '../Clipper/ContentClipper';
import { useInViewport } from '../useInViewport';
import useGridBlockLayer from '../UseGridBlockLayer';
import { BlockSettingsToolsContainer } from '../BlockSettings/BlockSettingsToolsContainer';
import { SelectionContext } from '../SelectedBlockInfoProvider';
import { gridPixelSize } from '../../shared/gridConfig';
import { CustomContextMenu, CustomContextMenuOptions } from '../../../CustomContextMenu/CustomContextMenu';
import PlaceHolder from '../Block/Placeholder';
import { BlockDimensionType, BlockPositionType, DraggableGridBlockWrapperProps } from './types';
import { useDraggableBlockManipulations } from './useDraggableBlockManipulations';
import { useSectionData } from '../../Sections/useSectionContext';
import { toggleSelectNoneClass } from 'components/editor/helpers/toggleSelectNoneClass';
import { GridBlockType } from '../../shared/gridBlockType';
import { SIZES } from '../../../../muiTheme/MuiDataGrid';

export const DraggableGridBlockWrapper: React.FC<DraggableGridBlockWrapperProps> = ({
  blockId,
  children,
  onMouseDown,
  isEditMode = false,
  lockAspectRatio = false,
  sumBorderWidth,
  disableResizingHandlers = false,
  tableRef,
}) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const {
    handleOnResizing,
    handleDrag,
    handleDragStart,
    handleOnResizeStop: handleResizeStop,
    handleStop,
  } = useDraggableBlockManipulations();

  const { bringGridToFront, bringGridForward, sendGridBackward, sendGridToBack } = useGridBlockLayer();
  const { blocksContent, getSectionBlocksLayer, sectionId } = useSectionData();
  const { blockConfig, type: blockType } = blocksContent[blockId];
  const { scrollToPosition } = useInViewport();
  const positionOnInteractionStart = useRef<BlockPositionType>({ xAxisPx: 0, yAxisPx: 0 });
  const dimensionOnInteractionStart = useRef<BlockDimensionType>({ heightPx: 0, widthPx: 0 });
  const { selectedBlockIdByWrapper } = useContext(SelectionContext);
  const isSelectedState = blockId === selectedBlockIdByWrapper;
  const isContentClipperActive = (isSelectedState || isEditMode) && !isDragging;
  const isMac = navigator.userAgent.includes('Mac');
  const isTableInEditMode = isEditMode && blockType === GridBlockType.TABLE && tableRef;
  let positionValueDelta = 0,
    reorderColumnWidth = 0;

  if (isTableInEditMode) {
    const currentTable = tableRef.current;

    const headerColumnElement = currentTable?.querySelector('.MuiDataGrid-columnHeaderReorder') as Element;
    const headerColumnWidth = (headerColumnElement && Math.floor(headerColumnElement?.getBoundingClientRect().width)) || 0;
    positionValueDelta = isEditMode ? SIZES.columnHeaderHeight * -1 : positionValueDelta;
    reorderColumnWidth = isEditMode ? headerColumnWidth * -1 : reorderColumnWidth;
  }

  const mapAvailableResizeHandle = () =>
    Object.keys(availableResizeHandle).reduce((resizeHandles, key) => {
      resizeHandles[key] = true;
      return resizeHandles;
    }, {});

  const blockSize = {
    width: blockConfig.width,
    height: blockConfig.height,
  };

  const currentBlock = {
    x: positionOnInteractionStart.current.xAxisPx,
    y: positionOnInteractionStart.current.yAxisPx,
    width: blockConfig.width,
    height: blockConfig.height,
  };

  const getResizingSides = disableResizingHandlers ? false : mapAvailableResizeHandle();
  const customContextMenuOptions: CustomContextMenuOptions = [
    {
      title: 'Bring to front',
      callback: () => bringGridToFront(blockConfig, getSectionBlocksLayer().greaterZIndexAvailable),
    },
    {
      title: 'Bring forward',
      callback: () => bringGridForward(blockConfig, blocksContent),
    },
    {
      title: 'Send backward',
      callback: () => sendGridBackward(blockConfig, blocksContent),
    },
    {
      title: 'Send to back',
      callback: () => sendGridToBack(blockConfig, getSectionBlocksLayer().lowerZIndexAvailable),
    },
  ];

  const customStyle = useMemo<React.CSSProperties>(
    () => ({
      position: 'absolute',
      top: 0,
      left: 0,
      transform: `translate(${blockConfig.x + reorderColumnWidth}px, ${blockConfig.y + positionValueDelta}px)`,
    }),
    [blockConfig.x, blockConfig.y, positionValueDelta, reorderColumnWidth]
  );

  const handleMouseDown = (e, blockId) => {
    onMouseDown?.(e, blockId);
  };

  const onStartHandle = () => {
    positionOnInteractionStart.current = { xAxisPx: blockConfig.x, yAxisPx: blockConfig.y };
    handleDragStart();
  };

  const onDragHandle = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      setIsDragging(true);
      scrollToPosition(data.y);
      handleDrag(e as MouseEvent, data, { ...blockConfig }, blockType);
    },
    [blockConfig.x, blockConfig.y, blockConfig.height, blockConfig.width]
  );

  const onStopHandle = (event: DraggableEvent) => {
    setIsDragging(false);
    handleStop(event as MouseEvent, positionOnInteractionStart.current, blockConfig, sectionId);
  };

  const onMouseDownHandle = (e) => {
    e.stopPropagation();
    handleMouseDown(e, blockId);
  };

  const onResize = (_event, direction, _refToElement, delta) => {
    handleOnResizing(direction, delta, positionOnInteractionStart.current, dimensionOnInteractionStart.current, blockConfig, blockType);
  };

  const onResizeStop: ResizeCallback = (_event, direction, _elementRef, delta) => {
    handleResizeStop(
      direction,
      delta,
      blockConfig,
      positionOnInteractionStart.current,
      dimensionOnInteractionStart.current,
      sumBorderWidth
    );
    toggleSelectNoneClass(false);
  };

  const onResizeStartHandle = (event) => {
    positionOnInteractionStart.current = { xAxisPx: blockConfig.x, yAxisPx: blockConfig.y };
    dimensionOnInteractionStart.current = {
      heightPx: blockConfig.height,
      widthPx: blockConfig.width,
    };
    event.stopPropagation();
    toggleSelectNoneClass(true);
  };

  const blockClassName = `block_${blockId} block-type-${blockType.toLowerCase()} editor__page__draggable ${isMac ? 'is_mac' : ''} ${
    isEditMode ? 'edit-state' : ''
  } ${isSelectedState ? 'selected-state' : ''}`;

  return (
    <CustomContextMenu options={customContextMenuOptions} gridBlockType={blockType}>
      <div style={{ zIndex: blockConfig.z, position: 'relative' }}>
        <DraggableCore
          onStart={onStartHandle}
          onDrag={onDragHandle}
          onStop={onStopHandle}
          scale={1}
          onMouseDown={onMouseDownHandle}
          grid={[gridPixelSize, gridPixelSize]}
          disabled={isEditMode}
        >
          <Resizable
            className={blockClassName}
            size={blockSize}
            scale={1}
            data-testid={'resizable-block-wrapper'}
            minWidth={gridPixelSize}
            style={customStyle}
            minHeight={gridPixelSize}
            grid={[gridPixelSize, gridPixelSize]}
            handleComponent={availableResizeHandle}
            enable={getResizingSides}
            onResizeStart={onResizeStartHandle}
            onResize={onResize}
            onResizeStop={onResizeStop}
            lockAspectRatio={lockAspectRatio}
          >
            <BlockSettingsToolsContainer blockId={blockId} />
            {children}
            {!isContentClipperActive && <ContentClipper blockId={blockId} />}
          </Resizable>
        </DraggableCore>
      </div>
      {isDragging && <PlaceHolder trackedBlocks={currentBlock} />}
    </CustomContextMenu>
  );
};

DraggableGridBlockWrapper.displayName = 'DraggableGridBlockWrapper';
