import { useInsightsContext } from './InsightsProvider';

import { Box, Fade } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';

const APPEARING_ANIMATION_DURATION = 200;
const DISAPPEARING_ANIMATION_DURATION = 0;

export function DocumentChangeTransition({ children }: PropsWithChildren) {
  const { documentId } = useInsightsContext();
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(false);
    const timer = setTimeout(() => setFadeIn(true), APPEARING_ANIMATION_DURATION);
    return () => clearTimeout(timer);
  }, [documentId]);

  return (
    <Fade
      in={fadeIn}
      appear={false}
      easing={{ enter: 'ease-in' }}
      timeout={{ enter: APPEARING_ANIMATION_DURATION, exit: DISAPPEARING_ANIMATION_DURATION }}
    >
      <Box>{children}</Box>
    </Fade>
  );
}
