import { useTranslation } from 'react-i18next';

import { Form } from 'components/form';
import { AsyncSelect, Select } from 'components/select';

import { ProspectOption, useProspect } from './useProspect';
import { renderContactFormItem } from './contact-form-item';
import { SenderOption } from './types';
import { useSender } from './useSender';
import { DocumentEntity } from 'services/documents/entities';
import { useDocumentSettingsForm } from './useDocumentSettingsForm';

export function DocumentSettingsForm({ data }: { data: DocumentEntity }) {
  const { t } = useTranslation();
  const { form, onValuesChange, initialValues, clearContact } = useDocumentSettingsForm(data);
  const prospect = useProspect();
  const sender = useSender();

  return (
    <Form form={form} initialValues={initialValues} layout="vertical" onValuesChange={onValuesChange}>
      <Form.Item name="prospect" label={t('document.form.account')}>
        <AsyncSelect<ProspectOption>
          isClearable
          placeholder={t('document.form.account_placeholder')}
          isLoading={prospect.isLoading}
          onMenuScrollToBottom={prospect.onMenuScrollToBottom}
          onChange={clearContact}
          loadOptions={prospect.loadOptions}
          defaultOptions={prospect.options}
        />
      </Form.Item>
      <Form.Item noStyle shouldUpdate={true}>
        {renderContactFormItem}
      </Form.Item>
      <Form.Item name="assignedUser" label={t('document.form.sender')}>
        <Select<SenderOption>
          isClearable
          placeholder={t('document.form.sender_placeholder')}
          isLoading={sender.isLoading}
          options={sender.options}
        />
      </Form.Item>
    </Form>
  );
}
