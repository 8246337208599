import { useCallback, useContext, useEffect, useState } from 'react';
import { SecondaryMenuItemTitle } from '../../../component';
import { Grid, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { SidePanelContextColumnsPropsType, SidePanelProviderContext } from '../../SidePanelModelsProvider';
import { useTableManipulation } from '../../../../GridDndEditor/Block/Table/useTableManipulation';
import { useBlockContentChangedHandler } from '../../../../hooks/UseBlockContentChangedHandler';
import { SelectionContext } from '../../../../GridDndEditor/SelectedBlockInfoProvider';
import { getIsCellEditingAllowed } from 'components/editor/GridDndEditor/Block/Table/tableUtils/customCellHelpers';

const MultiplierEditingToggle = () => {
  const { t } = useTranslation();
  const { toggledTableSettingsPanel } = useContext(SidePanelProviderContext) as SidePanelContextColumnsPropsType;
  const { selectedBlockIdByWrapper, selectedSectionId } = useContext(SelectionContext);
  const sectionId = selectedSectionId as string;
  const blockId = selectedBlockIdByWrapper as string;
  const { addCustomFieldToRow } = useTableManipulation();
  const label = { inputProps: { 'aria-label': 'Allow recipient to edit' } };
  const selectedModel = toggledTableSettingsPanel?.tableApi.selectedModel || undefined;
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const { row: selectedRow, field, colDef } = selectedModel as GridCellParams;
  const [isToggleAvailable, setIsToggleAvailable] = useState(true);
  const [isEditingActive, setIsEditingActive] = useState<boolean>(getIsCellEditingAllowed({ row: selectedRow, colDef }));

  const handleAllowEditingChange = useCallback(async () => {
    if (!selectedModel) return;
    const { id } = selectedRow;
    const currentIsEditingActive = !isEditingActive;
    setIsToggleAvailable(false);
    setIsEditingActive(currentIsEditingActive);

    try {
      const cellConfigObject = { isEditingActive: currentIsEditingActive };
      const updatedTableData = addCustomFieldToRow({ blockId, sectionId, field, id, cellConfigObject });
      if (!updatedTableData) return;
      const result = await blockContentChangedHandler(blockId, sectionId, updatedTableData);
      if (result.status === 'Ok') {
        setIsToggleAvailable(true);
      }
    } catch (error) {
      setIsToggleAvailable(true);
      throw new Error('Error while updating table data');
    }
  }, [blockId, sectionId, addCustomFieldToRow, blockContentChangedHandler, selectedModel]);

  useEffect(() => {
    if (selectedRow && colDef) {
      const isCellEditingAllowed = getIsCellEditingAllowed({ row: selectedRow, colDef });
      setIsEditingActive(isCellEditingAllowed);
    }
  }, [selectedModel]);

  return (
    <>
      <SecondaryMenuItemTitle>{t('document.grid.table.setting.allow_edit_multiplier')}</SecondaryMenuItemTitle>
      <Grid>
        <Switch
          disabled={!isToggleAvailable}
          data-testid="pricing-allow-editing-toggle"
          {...label}
          checked={isEditingActive}
          onChange={handleAllowEditingChange}
        />
      </Grid>
    </>
  );
};

export default MultiplierEditingToggle;
