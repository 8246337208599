import { Box, Typography } from '@mui/material';

export function DocumentCreatedDate({ date, show = true }: { date: string; show?: boolean }) {
  return (
    <Box sx={{ flex: '0 1 100px' }}>
      {show && (
        <Typography variant="labelSmall" textAlign="right" whiteSpace="nowrap" data-testid="document-created-date">
          {date}
        </Typography>
      )}
    </Box>
  );
}
