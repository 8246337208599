import { Box } from '@mui/material';

export function DocumentRightItems({ children }: { children: React.ReactNode }) {
  return (
    <Box
      onClick={(e) => e.stopPropagation()}
      sx={{ flex: '0 0 200px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
    >
      {children}
    </Box>
  );
}
