import { ThemeOptions } from '@mui/material';
import { type TypographyStyleOptions } from '@mui/material/styles/createTypography';
import './fonts.css';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    labelSmall: TypographyStyleOptions;
    labelMedium: TypographyStyleOptions;
  }

  interface TypographyVariantsOptions {
    labelSmall?: TypographyStyleOptions;
    labelMedium?: TypographyStyleOptions;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    labelSmall: true;
    labelMedium: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    extended: true;
    contained: true;
  }
}

declare module '@mui/material/Avatar' {
  interface AvatarPropsVariantOverrides {
    large: true;
  }
}

export const FenixTheme: ThemeOptions = {
  typography: {
    fontFamily: 'Gellix',
    h1: {
      fontFamily: 'Gellix',
      fontSize: '6rem',
      fontWeight: 400,
      lineHeight: '125%',
      letterSpacing: '1.5px',
    },
    h2: {
      fontFamily: 'Gellix',
      fontSize: '3.75rem',
      fontWeight: 400,
      lineHeight: '125%',
      letterSpacing: '0.5px',
    },
    h3: {
      fontFamily: 'Gellix',
      fontSize: '3rem',
      fontWeight: 400,
      lineHeight: '125%',
      letterSpacing: '0.5px',
    },
    h4: {
      fontFamily: 'Gellix',
      fontSize: '2.124rem',
      fontWeight: 400,
      lineHeight: '123.5%',
      letterSpacing: '0.25px',
    },
    h5: {
      fontFamily: 'Gellix',
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '133.4%',
      letterSpacing: '0.25px',
    },
    h6: {
      fontFamily: 'Gellix',
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '160%',
      letterSpacing: '0.25px',
    },
    body1: {
      fontFamily: 'Gellix',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '150%',
      letterSpacing: '0.15px',
    },
    body2: {
      fontFamily: 'Gellix',
      fontSize: '0.8125rem',
      fontWeight: 400,
      lineHeight: '143%',
      letterSpacing: '0.5px',
    },
    subtitle1: {
      fontFamily: 'Gellix',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '175%',
      letterSpacing: '0.15px',
    },
    labelSmall: {
      fontFamily: 'Gellix',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1rem',
      letterSpacing: '0.01rem',
    },
    labelMedium: {
      fontFamily: 'Gellix',
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
      letterSpacing: '0.03rem',
    },
  },
  palette: {
    text: {
      primary: 'rgba(0, 40, 58, 0.87)',
      secondary: 'rgba(0, 40, 58, 0.6)',
    },
    primary: {
      main: '#00283A',
    },
    secondary: {
      main: '#00B8D1',
      dark: '#009EB2',
    },
    success: {
      main: '#0D7741',
      dark: '#084627',
    },
    info: {
      main: '#303F9F',
      dark: '#1A237E',
    },
    warning: {
      main: '#FFC107',
      dark: '#FFA000',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#DC3856',
    },
    background: {
      default: '#EBF7FA',
      paper: '#FFFFFF',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          labelSmall: 'span',
        },
      },
      styleOverrides: {
        subtitle2: {
          fontSize: '0.875rem',
          fontWeight: '600',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: '600',
          borderRadius: '100px',
          textTransform: 'none',
        },
        contained: {
          '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 40, 58, 0.12)',
            color: 'rgba(0, 40, 58, 0.38)',
          },
        },
        containedSecondary: {
          boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
        },
        outlined: {
          '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 40, 58, 0.12)',
            color: 'rgba(0, 40, 58, 0.38)',
          },
        },
      },
      variants: [
        {
          props: { variant: 'extended' },
          style: {
            width: '232px',
            height: '56px',
            textTransform: 'capitalize',
            boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)',
            '&:hover': {
              background: '#00344a',
              boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
            },
            '&:active': {
              background: '#00344a',
              boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
            },
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#00283A',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: '#00283A',
          backgroundColor: '#F5F7F8',
          width: '2rem',
          height: '2rem',
          boxSizing: 'content-box',
          border: '0.125rem solid white',
        },
      },
      variants: [
        {
          props: { variant: 'large' },
          style: {
            width: '2.5rem',
            height: '2.5rem',
            color: '#00283A',
          },
        },
      ],
    },
  },
};
