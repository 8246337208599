import { Form } from 'antd';
import { DocumentSettingsFormValues } from './types';
import { useCallback } from 'react';
import { UpdateDocumentPayload } from 'services/documents/DocumentRepository';
import { DocumentEntity } from 'services/documents/entities';
import { constructInitialValues } from './utils';
import useUpdateDocumentMutation from 'hooks/useUpdateDocumentMutation';
import { useAppDispatch } from '../../grid/reduxStore/Store';
import { setInitialVariablesState } from '../../grid/reduxStore/allVariableSlice';

type Transformed = Omit<UpdateDocumentPayload, 'documentId'>;

function transformFormValues(changedValues: Partial<DocumentSettingsFormValues>, allValues: DocumentSettingsFormValues): Transformed {
  const transFormed: Transformed = {};

  if (changedValues.assignedUser !== undefined) {
    transFormed.assignedUser = changedValues.assignedUser?.value ? { id: changedValues.assignedUser.value } : null;
  }

  if (changedValues.contact !== undefined) {
    if (allValues.prospect) {
      let contact;

      if (changedValues.contact) {
        contact = {
          id: changedValues.contact.value,
          name: transFormed.prospect?.contact?.name || '',
          email: transFormed.prospect?.contact?.email || '',
        };
      }
      transFormed.prospect = {
        id: allValues.prospect.id,
        type: allValues.prospect.type,
        contact: contact,
      };
    }
  }
  if (changedValues.prospect !== undefined) {
    if (changedValues.prospect == null) {
      transFormed.prospect = null;
    } else {
      transFormed.prospect = { id: changedValues.prospect.id, type: changedValues.prospect.type, contact: null };
    }
  }

  return transFormed;
}

export function useDocumentSettingsForm(document: DocumentEntity) {
  const initialValues = constructInitialValues(document);
  const [form] = Form.useForm<DocumentSettingsFormValues>();
  const { mutate } = useUpdateDocumentMutation();
  const dispatch = useAppDispatch();

  const updateVariableValues = () => dispatch(setInitialVariablesState(document.contentId));

  const onValuesChange = useCallback(
    (changedValues: Partial<DocumentSettingsFormValues>, allValues: DocumentSettingsFormValues) => {
      const payload = transformFormValues(changedValues, allValues);
      mutate(
        { documentId: document.id, ...payload },
        {
          onSuccess: updateVariableValues,
        }
      );
    },
    [mutate]
  );

  const clearContact = () => {
    form.setFieldsValue({ contact: null });
  };

  return {
    form,
    initialValues,
    onValuesChange,
    clearContact,
  };
}
