import React from 'react';
import { ListItemIcon, MenuItem, IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Check } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import { cellMenu } from '../defaultData';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../../../../muiTheme/MuiDataGrid';

interface TableRowTypes {
  options: { sign: string; value: string }[];
  changeCellFormatValue: (value: string) => void;
  selectedValue: string;
  hideCellValue: string;
  isHidden: boolean;
}

function CellDropDown({ hideCellValue, options, changeCellFormatValue, selectedValue, isHidden }: TableRowTypes) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (sign: string) => {
    if (typeof sign === 'string') {
      changeCellFormatValue(sign);
    }
    if (sign !== cellMenu[2].sign) {
      setAnchorEl(null);
    }
  };

  return (
    <div data-testid="cell-dropdown-wrapper">
      <IconButton
        data-testid="cell-dropdown-button"
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'cell-menu-open' : 'cell-menu-closed'}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          margin: 0,
          borderRadius: 0,
          border: 'none',
          transition: 'all 300ms easy',
          padding: 0,
          transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
      >
        <ArrowDropDownIcon data-testid="cell-dropdown-icon" />
      </IconButton>
      <Menu
        data-testid="cell-dropdown-menu"
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'cell-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              width: '20ch',
            },
          },
        }}
      >
        {options?.map(({ sign, value }, index) => {
          const isHideCellActive = isHidden && sign === hideCellValue;
          const isMenuItemSelected = selectedValue === cellMenu[index].sign || isHideCellActive;

          return (
            <MenuItem
              sx={{ color: '#191C1E', padding: '6px 16px', lineHeight: '40px' }}
              key={value}
              selected={isMenuItemSelected}
              onClick={() => handleClose(sign)}
              data-testid="cell-dropdown-menu-item"
            >
              {t(value)}
              {isHideCellActive && (
                <ListItemIcon sx={{ marginLeft: 'auto' }}>
                  <Check sx={{ color: COLORS.colorHeaderRow }} />
                </ListItemIcon>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
export default CellDropDown;
