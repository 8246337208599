import { GridCallbackDetails, GridCellParams } from '@mui/x-data-grid-pro';
import { setActiveTableSettingsPanel, setToggledDesignSettingModelType } from '../../../../grid/reduxStore/blockStyleSettingsSlice';
import { useAppDispatch } from '../../../../grid/reduxStore/Store';
import { useContext } from 'react';
import { SidePanelProviderContext } from '../../SidePanelModelsProvider';
import { TableSettingsTypes } from '../types';

const useTableRowSettingsPanel = () => {
  const dispatch = useAppDispatch();
  const { setToggledTableSettingsPanel } = useContext(SidePanelProviderContext);

  const handleRowSettingsPanel = (cellSelection: GridCellParams, tableCallbackDetails: GridCallbackDetails) => {
    dispatch(setToggledDesignSettingModelType({ type: null }));
    dispatch(setActiveTableSettingsPanel({ type: TableSettingsTypes.TABLE_ROWS }));
    setToggledTableSettingsPanel({
      tableApi: { selectedModel: cellSelection, tableCallbackDetails: tableCallbackDetails },
    });
  };

  return { handleRowSettingsPanel };
};

export default useTableRowSettingsPanel;
