import { Alert } from '@mui/material';
import { ComponentProps, PropsWithChildren } from 'react';

type MessageBarProps = PropsWithChildren<ComponentProps<typeof Alert>> & {
  dataTestId?: string;
};

export function MessageBar({ children, action, dataTestId }: MessageBarProps) {
  return (
    <Alert
      data-testid={dataTestId}
      sx={{ backgroundColor: '#d9e7ef', alignItems: 'center', '& .MuiAlert-action': { padding: 0 } }}
      icon={false}
      action={action}
    >
      {children}
    </Alert>
  );
}
