import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderIconButtonBig = styled(IconButton)({
  borderRadius: '6.25rem',
  padding: '0.375rem 1rem',
  gap: '0.5rem',
  '& svg': {
    width: '1.25rem',
    height: '1.25rem',
  },
  '&:disabled': {
    border: '1px solid rgba(0, 40, 58, 0.38)',
    background: 'rgba(0, 40, 58, 0.12)',
  },
});
