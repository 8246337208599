import { Document } from 'services/documents/Document';

export const statusColors: Record<Document['status'], string> = {
  Draft: '#4fb5eb',
  Sent: '#13bcd5',
  Viewed: '#8660d8',
  Unsigned: '#f23d94',
  'Awaiting Signature': '#f23d94',
  Won: '#18e37d',
  Lost: '#f23d5e',
  Approval: '#ffbf0a',
  Approved: '#a5ced4',
};
