import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material';
import { openNotification } from 'components';
import EmptyState from 'components/emptyState';
import { PrimaryActionButton } from 'components/emptyState/components/emptyStateActionButtons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteAllDocuments } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import NoTrash from '../../../images/empty-state/No_Trash.svg';
import { DocumentListSkeleton } from '../components/DocumentListSkeleton';
import { MessageBar } from '../components/MessageBar';
import { Pagination } from '../components/pagination/Pagination';
import { usePagination } from '../components/pagination/usePagination';
import { useDocumentsContext } from '../tabs/DocumentsProvider';
import { useFilteredDocuments } from '../tabs/useFilteredDocuments';
import { useUrlSearch } from '../utils/useUrlSearch';
import { EmptyTrashModal } from './EmptyTrashModal';
import { TrashList } from './TrashList';

type Props = {
  setCurrentTab: (key: string) => void;
};

export function TrashTab({ setCurrentTab }: Props) {
  const { t } = useTranslation();
  const [modalEmptyTrashVisible, setModalEmptyTrashVisible] = useState(false);

  const { currentPageFilter, handleQueryParams } = useUrlSearch();
  const { filteredDocumentList, setFilteredDocumentList } = useFilteredDocuments({
    filterTrash: true,
  });
  const { currentPage, setCurrentPage, paginatedDocs } = usePagination({ documentList: filteredDocumentList, currentPageFilter });

  const { isLoading: isLoadingDocumentList, refreshDocumentList, isRefreshing: isRefreshingDocumentList } = useDocumentsContext();

  useEffect(() => {
    const handlePageFilter = (value: string) => {
      if (value === currentPageFilter) {
        return;
      }
      handleQueryParams({ filterPage: value });
    };

    handlePageFilter(currentPage.toString());
  }, [currentPage]);

  const handleEmptyTrashAction = async () => {
    try {
      await deleteAllDocuments();
      setFilteredDocumentList([]);
      setCurrentPage(1);
      refreshDocumentList();
    } catch (error) {
      openNotification({
        type: 'error',
        title: t('pipeline.error.delete_error_msg'),
        description: t('pipeline.error.delete_error_description'),
        placement: 'top',
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <header className="pipeline__list-header">
        <Typography variant="h4">{t('pipeline.tabs.trash_document_list.title')}</Typography>
      </header>

      <MessageBar
        dataTestId="trash-tab-message-bar"
        action={
          filteredDocumentList.length > 0 ? (
            <Button sx={{ borderRadius: '8px' }} onClick={() => setModalEmptyTrashVisible(true)}>
              {t('pipeline.tabs.trash_document_list.empty_action')}
            </Button>
          ) : undefined
        }
      >
        {t('pipeline.tabs.trash_document_list.auto_empty_message')}
      </MessageBar>

      <Paper elevation={0} sx={{ borderCollapse: 'collapse', borderRadius: '24px', opacity: isRefreshingDocumentList ? 0.5 : 1 }}>
        <Box height="52px">
          {filteredDocumentList.length > 0 && paginatedDocs.length > 0 && (
            <Pagination
              sx={{ opacity: isRefreshingDocumentList ? 0 : 1 }}
              totalDocuments={filteredDocumentList.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </Box>

        {isLoadingDocumentList && !isRefreshingDocumentList && <DocumentListSkeleton sx={{ overflowY: 'auto', maxHeight: '100vh' }} />}

        {!isLoadingDocumentList && paginatedDocs.length === 0 && (
          <Box padding={6} marginTop={2}>
            <EmptyState
              headerText={t('pipeline.empty_state.trash.header')}
              supportingText={t('pipeline.empty_state.trash.supporting_text')}
              primaryGraphic={NoTrash}
              primaryActionButton={
                <PrimaryActionButton buttonText={t('pipeline.empty_state.trash.button_text')} onClick={() => setCurrentTab('documents')} />
              }
            />
          </Box>
        )}

        {(!isLoadingDocumentList || isRefreshingDocumentList) && filteredDocumentList.length > 0 && (
          <Box position="relative">
            <TrashList sx={{ overflowY: 'auto', maxHeight: '100vh' }} documents={paginatedDocs} />

            {isRefreshingDocumentList && (
              <Box position="absolute" top="50%" left="50%" sx={{ transform: 'translate(-50%, -50%)' }} zIndex={1}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        )}
      </Paper>

      <EmptyTrashModal onConfirm={handleEmptyTrashAction} visible={modalEmptyTrashVisible} setVisible={setModalEmptyTrashVisible} />
    </Box>
  );
}
