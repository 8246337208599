import { useNavigate } from 'react-router-dom';

import { URLS } from 'utils/urls';
import { useInsightsContext } from '../InsightsProvider';

export function useInsightsPanelHeaderNavigation() {
  const { documentId } = useInsightsContext();
  const navigate = useNavigate();

  const navigateToEditor = () => {
    navigate(URLS.editDocument(documentId));
  };

  const navigateToPreview = () => {
    navigate(`${URLS.preview}/${documentId}`);
  };

  return {
    navigateToEditor,
    navigateToPreview,
  };
}
