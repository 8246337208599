import { Box, ImageList, ImageListItem } from '@mui/material';
import { EditorContentContext } from 'providers/EditorContentContext';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentImageLibraryApiResponse } from 'services/documents/DocumentRepository';
import { useDocumentImageLibrary } from '../../../hooks/useDocumentImageLibrary';
import { openNotification } from '../../notification';
import ImageUploadButton from './ImageUploadButton/ImageUploadButton';
import './style.less';

export default function ImageLibrary() {
  const { contentId } = useContext(EditorContentContext);
  const { imageBlockContent } = useContext(EditorContentContext);
  const IMAGE_LIBRARY_COLUMNS = 2,
    IMAGE_LIBRARY_GAP = 8;

  const imageQueryString = '?w=248&fit=crop&auto=format';
  const imageQueryStringHighRes = '?w=248&fit=crop&auto=format&dpr=2 2x';
  const { result, isError, isFetched, refetch } = useDocumentImageLibrary(contentId);
  const { t } = useTranslation();
  let libraryImages = result;

  if (isError) {
    openNotification({
      type: 'error',
      title: t('document.image.errors.library_image_load_fail'),
      description: '',
      placement: 'topRight',
    });
  }

  // Filter out unique image grid blocks
  const imageList = useMemo(() => {
    const uniqueUrls = new Set<string>();
    if (imageBlockContent) {
      imageBlockContent.forEach((content) => uniqueUrls.add(content));
    }

    if (isFetched && result) {
      (libraryImages as DocumentImageLibraryApiResponse).forEach((image) => {
        uniqueUrls.add(image.link);
      });
    }

    return uniqueUrls;
  }, [imageBlockContent, isFetched, result]);

  const handleDragStart = useCallback((event: React.DragEvent<HTMLImageElement>, item: string) => {
    event.dataTransfer.setData('text/uri-list', item);
  }, []);

  const refreshImages = useCallback(async () => {
    const response = await refetch();
    if (response?.data) {
      libraryImages = response.data;
    }
  }, [refetch]);

  return (
    <div className="image__library__container">
      <div className="image__container_title">IMAGES</div>
      <div className="image__heading__section">LIBRARY</div>
      <div className="image__content__section">
        <Box className="image__library__box">
          <ImageList variant="masonry" cols={IMAGE_LIBRARY_COLUMNS} gap={IMAGE_LIBRARY_GAP} draggable={true}>
            <ImageListItem key={0}>
              <ImageUploadButton data-testid={'upload-button'} refreshImages={refreshImages} />
            </ImageListItem>
            {Array.from(imageList).map((item, index) => (
              <ImageListItem key={index}>
                <img
                  srcSet={`${item}${imageQueryStringHighRes}`}
                  src={`${item}${imageQueryString}`}
                  alt={`Image ${index}`}
                  loading="lazy"
                  draggable={true}
                  onDragStart={(event) => handleDragStart(event, item)}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </div>
    </div>
  );
}

ImageLibrary.displayName = 'ImageLibrary';
