import { useEffect, useRef, useState } from 'react';

type UseManageErrorsProps<T> = {
  errorsToPersist: T[];
};

export function useManageErrors<T extends string>({ errorsToPersist }: UseManageErrorsProps<T>) {
  const errorsRef = useRef<Partial<Record<T, boolean>>>({});
  const [errors, setErrors] = useState<Partial<Record<T, boolean>>>(errorsRef.current);

  const setError = (key: T, isError = true) => {
    errorsRef.current[key] = isError;
    setErrors({ ...errorsRef.current });
  };

  const resetErrors = () => {
    const newErrors: Partial<Record<T, boolean>> = {};
    errorsToPersist.forEach((error) => {
      newErrors[error] = errorsRef.current[error];
    });
    setErrors(newErrors);
  };

  const hasErrors = Object.values(errors).some((error) => error);

  useEffect(() => {
    errorsRef.current = {};
    return () => {
      errorsRef.current = {};
    };
  }, []);

  return {
    errors,
    setError,
    resetErrors,
    hasErrors,
  };
}
