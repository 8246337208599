import { useCallback, useMemo, useState } from 'react';
import { gridPixelSize } from '../../../shared/gridConfig';
import { DataGridPro, GridCellParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { PreviewBlock } from './PreviewBlock';
import { BlockContent } from '../../../grid/reduxStore/editorSlice';
import { ColumnTypeIdentifier, TableColumnType, TableRowType } from '../../../grid/reduxStore/table.types';
import { useApplyCustomRulesToColumnsInPreview } from './useApplyCustomRulesToColumnsInPreview';
import { getIsCellEditingAllowed } from '../Table/tableUtils/customCellHelpers';
import { DEFAULT_DECIMAL_PLACES } from '../Table/defaultData';
import { useTableCellModes } from '../Table/useTableCellModes';

interface PreviewTableBlockProps {
  block: BlockContent;
  isDocumentLocked: boolean;
}

export function PreviewTableBlock({ block, isDocumentLocked }: PreviewTableBlockProps) {
  const apiRef = useGridApiRef();
  const columnsData = block?.contentTable?.columns;
  const tableColumns = useMemo(
    () =>
      (isDocumentLocked
        ? columnsData?.filter((column: TableColumnType) => column.columnType !== ColumnTypeIdentifier.OPTIONAL)
        : columnsData || []) as TableColumnType[],
    [block?.contentTable?.columns]
  );

  const contentTableRows = block?.contentTable?.rows || [];
  const filteredRows = useMemo(() => contentTableRows.filter((row) => !row.isRowHidden), [contentTableRows]);
  const [allTableRows, setAllTableRows] = useState<TableRowType[]>(filteredRows);
  const { handleCellClick, handleCellModesModelChange, cellModesModel } = useTableCellModes();

  const { applyCustomRulesToColumnsInPreview } = useApplyCustomRulesToColumnsInPreview(
    block.blockConfig.id,
    block.contentTable?.metadata.pricingDecimalPlaces || DEFAULT_DECIMAL_PLACES
  );
  const handleIsCellEditable = (params: GridCellParams): boolean => {
    const { row, colDef } = params;
    if (isDocumentLocked) {
      return false;
    }

    const isCellEditingAllowed = getIsCellEditingAllowed({ row, colDef });

    return isCellEditingAllowed;
  };

  const updateRowsOptionalStatus = useCallback((updatedRow: TableRowType) => {
    const { rows } = apiRef.current.store.value;
    const { dataRowIdToModelLookup, dataRowIds } = rows;
    const updatedRows = dataRowIds.map((rowId: string) =>
      dataRowIdToModelLookup[rowId].id === updatedRow.id
        ? { ...dataRowIdToModelLookup[rowId], isRowOptional: updatedRow.isRowOptional }
        : dataRowIdToModelLookup[rowId]
    );
    setAllTableRows(updatedRows);
  }, []);

  const gridColumns = useMemo(
    () => applyCustomRulesToColumnsInPreview(tableColumns, updateRowsOptionalStatus, isDocumentLocked),
    [tableColumns, applyCustomRulesToColumnsInPreview, updateRowsOptionalStatus]
  );

  return (
    <PreviewBlock key={block.blockConfig.id} gridBlock={block} gridSystemInPx={gridPixelSize}>
      <DataGridPro
        apiRef={apiRef}
        rows={allTableRows}
        columns={gridColumns}
        className={'py-selected-state'}
        isCellEditable={(params) => handleIsCellEditable(params)}
        onCellClick={handleCellClick}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        style={{ width: block.blockConfig.width }}
      />
    </PreviewBlock>
  );
}
