import { IconButton, Tooltip } from '@mui/material';
import IcoInsights from 'components/icon/icons/IcoInsights';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useTranslation } from 'react-i18next';
import { FeatureFlags } from 'utils/featureFlags';
import { openInsights, useOnInsightsClose } from '../../insights';

interface InsightsActionProps {
  documentId: string;
  refreshDocumentList: () => void;
}

export function InsightsAction({ documentId, refreshDocumentList }: InsightsActionProps) {
  const { t } = useTranslation();
  const featureFlags = useFeatureFlag([FeatureFlags.documentInsights]);
  const isDocumentInsightsEnabled = featureFlags[FeatureFlags.documentInsights];

  const handleOpenInsights = () => openInsights(documentId);
  useOnInsightsClose((isExpanded) => {
    if (!isExpanded) return;
    refreshDocumentList();
  });

  if (!isDocumentInsightsEnabled) {
    return null;
  }

  return (
    <Tooltip title={t('pipeline.insights.open_insights_button_tooltip')}>
      <IconButton data-testid={`insights-button-${documentId}`} color="primary" onClick={handleOpenInsights}>
        <IcoInsights />
      </IconButton>
    </Tooltip>
  );
}
