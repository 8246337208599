import { Box, List, SxProps, Theme } from '@mui/material';
import { forwardRef, ReactNode } from 'react';

type DocumentsListProps = {
  dataTestId: string;
  sx: SxProps<Theme>;
  children: ReactNode;
};

export const DocumentsList = forwardRef<HTMLDivElement, DocumentsListProps>(({ children, dataTestId, sx }, ref) => {
  return (
    <Box
      data-testid={dataTestId}
      ref={ref}
      sx={{
        ...sx,
        borderBottomLeftRadius: '24px',
        borderBottomRightRadius: '24px',
      }}
    >
      <List
        sx={{
          padding: 0,
          '& > div:last-of-type > li': {
            borderBottomLeftRadius: '24px',
            borderBottomRightRadius: '24px',
          },
        }}
      >
        {children}
      </List>
    </Box>
  );
});

DocumentsList.displayName = 'DocumentsList';
