import { ColumnTypeIdentifier, RowTypesIdentifier } from '../../../../grid/reduxStore/table.types';
import OptionalRowInput from './OptionalRowInput';

export const CustomTableCell = ({ params, value }: { params: any; value: string }) => {
  if (!params) {
    return <></>;
  }

  const rowType = params?.row?.rowType?.toLowerCase();
  const columnType = params?.colDef?.columnType?.toString().toLowerCase();
  const isRenderInputActive = params.colDef.columnType === ColumnTypeIdentifier.OPTIONAL && params.row.rowType === RowTypesIdentifier.BODY;
  const valueDisplay = value || '';
  const isRowOptional = params?.row?.isRowOptional ?? false;
  const isOptionalToggleAvailable = params?.row?.isOptionalToggleAvailable && isRenderInputActive;

  return (
    <span data-testid="render-cell-test" data-testid-row-type={rowType} data-testid-column-type={columnType}>
      {isOptionalToggleAvailable ? <OptionalRowInput isRowOptional={isRowOptional} id={params.id} /> : valueDisplay}
    </span>
  );
};

export const CustomTableCellInPreview = ({ params, value }: { params: any; value: string; children?: React.ReactNode }) => {
  const rowType = params?.row?.rowType?.toLowerCase();
  const columnType = params?.colDef?.columnType?.toString().toLowerCase();
  const valueDisplay = value || '';

  return (
    <span data-testid="render-cell-test-preview" data-testid-row-type={rowType} data-testid-column-type={columnType}>
      {valueDisplay}
    </span>
  );
};
