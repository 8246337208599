import { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { TableRowType, TotalRowTypesIdentifier } from '../../../grid/reduxStore/table.types';
import PricingTableTotalFooterRow from './PricingTableTotalFooterRow';
import { RootState, useAppDispatch } from 'components/editor/grid/reduxStore/Store';
import { selectContentTable, setSelectedRow } from 'components/editor/grid/reduxStore/editorSlice';

import CellDropdownWrapper from './CellDropdownWrapper';
import { cellMenu, setOfRowsForPricingTable } from './defaultData';
import TooltipInputRedux from './components/TooltipInputRedux';
import { setActiveTableSettingsPanel, setToggledDesignSettingModelType } from '../../../grid/reduxStore/blockStyleSettingsSlice';
import { TableSettingsTypes } from '../../../SidePanel/content/TableSettings/types';
import { useBlockContentChangedHandler } from '../../../hooks/UseBlockContentChangedHandler';
import { isTaxOrDiscountRow } from '../../../../../muiTheme/dataGridUtils';
import { SidePanelProviderContext } from '../../../SidePanel/content';
import { Box } from '@mui/material';
import { COLORS, SIZES, SPACING } from '../../../../../muiTheme/MuiDataGrid';

type PricingTableTotalsFooterType = {
  isEditState: boolean;
  isEditMode: boolean;
  sectionId: string;
  blockId: string;
  sumOfSubtotalColumn: number;
};

const FORMATTER_KEY = 'price';

export const PricingTableTotalsFooter = ({ isEditState, sectionId, blockId, sumOfSubtotalColumn }: PricingTableTotalsFooterType) => {
  const dispatch = useAppDispatch();
  const { setToggledTableSettingsPanel } = useContext(SidePanelProviderContext);
  const { activeTableSettingsPanel } = useSelector((state: RootState) => state.blockStyleSettings);
  const isActiveFooterPanel = activeTableSettingsPanel === TableSettingsTypes.TABLE_SETTINGS_TOTAL_ROWS;
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const tableData = useSelector((state: RootState) => selectContentTable(state, sectionId, blockId)) || undefined;
  const { metadata, selectedRow } = useSelector((state: RootState) => selectContentTable(state, sectionId, blockId));
  const footerTotalRows = metadata.totalRows;
  const totalTableRows = footerTotalRows || setOfRowsForPricingTable;
  const selectedRowId = selectedRow?.id;
  const inputRefs = useRef({});

  const handleRowClick = (e, row) => {
    const isInput = e.target instanceof HTMLInputElement;
    const isActiveRow = selectedRow?.id === row.id;
    if ((isInput && isActiveRow) || !isEditState) return;

    if (isEditState) {
      if (row.id !== selectedRow?.id) {
        dispatch(setSelectedRow({ sectionId, blockId, row }));
      }
      setToggledTableSettingsPanel(null);
      dispatch(setToggledDesignSettingModelType({ type: null }));
      dispatch(setActiveTableSettingsPanel({ type: TableSettingsTypes.TABLE_SETTINGS_TOTAL_ROWS }));
    }
  };

  const getRowValue = (row: TableRowType): string => {
    if (row.rowType === TotalRowTypesIdentifier.SUBTOTAL) {
      return sumOfSubtotalColumn.toString();
    }
    return '';
  };

  const onInputChange = async (value: string) => {
    const updatedRows = totalTableRows?.map((row) => (row.id === selectedRow?.id ? { ...row, price: value } : row));
    const newTableData = {
      ...tableData,
      metadata: {
        ...metadata,
        totalRows: updatedRows,
      },
    };
    await blockContentChangedHandler(blockId, sectionId, newTableData);
  };

  useEffect(() => {
    if (!isActiveFooterPanel) {
      dispatch(setSelectedRow({ sectionId, blockId, row: null }));
    }
  }, [isActiveFooterPanel]);

  useEffect(() => {
    if (selectedRowId && inputRefs.current[selectedRowId]) {
      inputRefs.current[selectedRowId].focus();
    }
  }, [selectedRow]);

  return (
    <>
      {totalTableRows?.map((row) => {
        const isActiveRow = selectedRow?.id === row.id && isTaxOrDiscountRow(row);
        const value = row[FORMATTER_KEY];
        const cellConfigObject = row?.cellConfig?.[FORMATTER_KEY];
        const isHidden = !!cellConfigObject?.hideCell ?? false;
        const valueFormatter = (cellConfigObject?.valueFormatter || '') as string;
        const isRowSelected = row.id === selectedRowId;
        const displayedValue = `${
          valueFormatter === cellMenu[0].sign ? `${valueFormatter + ' ' + value}` : `${value + ' ' + valueFormatter}`
        }`;

        return (
          <PricingTableTotalFooterRow
            key={row.id}
            onClick={(e) => handleRowClick(e, row)}
            title={row.title}
            value={getRowValue(row)}
            isRowSelected={isRowSelected}
            isEditState={isEditState}
          >
            <Box
              data-testid={`${row.title.toLowerCase()}-row-tooltip-wrapper`}
              sx={{ backgroundColor: isHidden ? COLORS.greyedBackgroundColor : 'transparent' }}
            >
              {isActiveRow ? (
                <TooltipInputRedux
                  inputRefs={inputRefs}
                  rowId={row.id}
                  isHidden={isHidden}
                  value={value}
                  onInputChange={onInputChange}
                  rowType={row.rowType}
                  pricingDecimalPlaces={metadata?.pricingDecimalPlaces}
                >
                  <CellDropdownWrapper
                    isHidden={isHidden}
                    valueFormatter={valueFormatter}
                    cellConfigObject={cellConfigObject}
                    data={row}
                    blockId={blockId}
                    sectionId={sectionId}
                  />
                </TooltipInputRedux>
              ) : (
                <span style={{ lineHeight: SIZES.defaultRowHeight, marginRight: SPACING.sm }}>{displayedValue}</span>
              )}
            </Box>
          </PricingTableTotalFooterRow>
        );
      })}
    </>
  );
};
