import { useState } from 'react';
import Button from 'components/button';
import Icon from 'components/icon';
import { DocumentShare } from '../document-share';
import { DocumentShareModal } from '../document-share-modal';
import { Header } from '../document-share-modal/header';
import { useTranslation } from 'react-i18next';
import { type QueryObserverResult } from 'react-query';
import { DocumentEntity } from 'services/documents/entities';

interface ShareDocumentProps {
  documentId: string;
  documentTitleWithCompanyName: string;
  refetchDocument: () => Promise<QueryObserverResult<DocumentEntity, unknown>>;
}

export function ShareDocument({ documentId, documentTitleWithCompanyName, refetchDocument }: ShareDocumentProps) {
  const { t } = useTranslation();
  const [isShareModalVisible, setIsVisible] = useState(false);
  const closeShareModal = () => setIsVisible(false);
  const openShareModal = () => setIsVisible(true);

  const onDocumentSent = () => {
    refetchDocument();
    closeShareModal();
  };

  return (
    <>
      <Button
        icon={<Icon name="IcoSend" title={t('share_document')} />}
        variant="positive"
        type="primary"
        className="send-button"
        onClick={openShareModal}
      >
        {t('share_document')}
      </Button>
      <DocumentShareModal
        open={isShareModalVisible}
        onCancel={closeShareModal}
        title={<Header title={t('share.title')} description={documentTitleWithCompanyName} />}
        destroyOnClose
      >
        <DocumentShare documentId={documentId} onSuccess={onDocumentSent} />
      </DocumentShareModal>
    </>
  );
}
