import React, { MutableRefObject, useEffect, useState } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material';
import { SIZES } from '../../../../../../muiTheme/MuiDataGrid';
import { useTranslation } from 'react-i18next';

type InputWithToolTipsFooterProps = Partial<GridRenderEditCellParams> & {
  pricingDecimalPlaces: number;
  tooltipTitle?: string | null;
  onInputChange?: (value: string) => void;
  children?: React.ReactNode;
  rowType: string;
  value: number;
  rowId: string;
  inputRefs: MutableRefObject<Record<string, any>>;
};

const TotalRowToolTip = styled('span')<{
  isHidden: boolean;
}>(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  fontSize: '1rem',
  paddingRight: 0,
  input: {
    border: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: 'right',
    lineHeight: SIZES.defaultRowHeight,
    width: '100%',
    backgroundColor: 'transparent',
    MozAppearance: 'textfield',
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));

export const TooltipInputRedux: React.FC<InputWithToolTipsFooterProps> = ({
  rowId,
  inputRefs,
  onInputChange,
  children,
  value = 0,
  isHidden,
}) => {
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState(value);
  const tooltipTitle = isHidden && t('document.grid.table.cell_hidden_tooltip');

  const handleOnChange = async (value: string) => {
    value = value.replace(/e/gi, '');

    setLocalValue(value);
    if (onInputChange) {
      onInputChange(value);
    }
  };
  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <Tooltip title={tooltipTitle} placement="bottom">
      <TotalRowToolTip isHidden={isHidden}>
        <input
          ref={(el) => (inputRefs.current[rowId] = el)}
          className="py-custom-input"
          type={'number'}
          onChange={(e) => handleOnChange(e.target.value)}
          data-testid="input-with-tooltips"
          value={localValue || ''}
        />
        {children}
      </TotalRowToolTip>
    </Tooltip>
  );
};

export default TooltipInputRedux;
