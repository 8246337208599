import Check from '@mui/icons-material/Check';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Document } from 'services/documents/Document';
import './StatusFilterBar.less';

type StatusFilterBarProps = {
  selectedItemStatus: string[];
  handleStatusButtonOnChangeClick: (event: React.MouseEvent<HTMLElement>, newStatus: string[]) => void;
};

const documentStatuses: Document['status'][] = ['Draft', 'Approval', 'Approved', 'Sent', 'Viewed', 'Awaiting Signature', 'Lost', 'Won'];

export function StatusFilterBar({ selectedItemStatus, handleStatusButtonOnChangeClick }: StatusFilterBarProps) {
  return (
    <ToggleButtonGroup
      className="status-filter-bar"
      value={selectedItemStatus}
      onChange={handleStatusButtonOnChangeClick}
      data-testid="status-filter-bar"
    >
      <ToggleButton className="status-button" value="All" selected={selectedItemStatus.includes('All')}>
        {selectedItemStatus.includes('All') && <Check fontSize="small" />}
        All
      </ToggleButton>

      {documentStatuses.map((status) => (
        <ToggleButton className="status-button" key={status} value={status} selected={selectedItemStatus.includes(status)}>
          {selectedItemStatus.includes(status) && <Check fontSize="small" />}
          {status}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
