import { Tooltip } from '@mui/material';
import IcoChevronLeft from 'components/icon/icons/IcoChevronLeft';
import IcoChevronRight from 'components/icon/icons/IcoChevronRight';
import { useTranslation } from 'react-i18next';
import { useInsightsContext } from '../InsightsProvider';
import { HeaderIconButton } from './HeaderIconButton';

export function NavigationButtons() {
  const { t } = useTranslation();
  const { documentId, documents, setCurrentDocumentId } = useInsightsContext();

  const currentDocumentIndex = documents.findIndex((doc) => doc.id === documentId);
  const hasNext = currentDocumentIndex < documents.length - 1;
  const hasPrevious = currentDocumentIndex > 0;

  const handleClickPrevious = () => {
    if (!hasPrevious) return;
    const previousIndex = currentDocumentIndex - 1;
    setCurrentDocumentId(documents[previousIndex].id);
  };

  const handleClickNext = () => {
    if (!hasNext) return;
    const nextIndex = currentDocumentIndex + 1;
    setCurrentDocumentId(documents[nextIndex].id);
  };

  return (
    <>
      <Tooltip title={hasPrevious ? t('pipeline.insights.header.previous_tooltip') : undefined}>
        <span>
          <HeaderIconButton disabled={!hasPrevious} data-testid="insights-navigation-left" onClick={handleClickPrevious}>
            <IcoChevronLeft />
          </HeaderIconButton>
        </span>
      </Tooltip>
      <Tooltip title={hasNext ? t('pipeline.insights.header.next_tooltip') : undefined}>
        <span>
          <HeaderIconButton disabled={!hasNext} data-testid="insights-navigation-right" onClick={handleClickNext}>
            <IcoChevronRight />
          </HeaderIconButton>
        </span>
      </Tooltip>
    </>
  );
}
