import { Chip, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Document } from 'services/documents/Document';
import { statusColors } from '../utils/statusColors';

export const DocumentStatusChip = ({ status }: { status: Document['status'] }) => {
  return (
    <Chip
      label={<Typography variant="labelSmall">{status}</Typography>}
      variant="filled"
      size="small"
      sx={{
        padding: '2px 4px',
        fontSize: '.75rem',
        fontWeight: '500',
        '&.MuiChip-root': { backgroundColor: alpha(statusColors[status], 0.2) },
      }}
    />
  );
};
