import { useContext } from 'react';
import { useTableManipulation } from './useTableManipulation';
import { SidePanelProviderContext } from 'components/editor/SidePanel/content/SidePanelModelsProvider';
import { GridApiPro } from '@mui/x-data-grid-pro';
import InputWithTooltips from './components/InputWithTooltips';
import { useBlockContentChangedHandler } from 'components/editor/hooks/UseBlockContentChangedHandler';
import { useTranslation } from 'react-i18next';

type TooltipInputProps = {
  params: any;
  blockId: string;
  decimalPlaces: number;
  sectionId: string;
};

const CellInputs = ({ params, blockId, decimalPlaces, sectionId }: TooltipInputProps): React.ReactElement => {
  const { t } = useTranslation();
  const { getUpdatedRowsTableDataFromCellEdit } = useTableManipulation();
  const blockContentChangedHandler = useBlockContentChangedHandler();

  const { toggledTableSettingsPanel, setToggledTableSettingsPanel } = useContext(SidePanelProviderContext);

  const { id, field, row } = params;
  const isCellHidden = row?.cellConfig?.[field]?.hideCell;
  const tooltipTitle = isCellHidden && t('document.grid.table.cell_hidden_tooltip');

  const handleOnInputValueChange = async (value: string) => {
    const updatedTableData = getUpdatedRowsTableDataFromCellEdit(params, value, blockId, sectionId);
    if (!updatedTableData) return;
    await blockContentChangedHandler(blockId, sectionId, updatedTableData);
  };

  const handleOnInputOnBlur = async (roundedValue) => {
    const updatedTableData = getUpdatedRowsTableDataFromCellEdit(params, roundedValue, blockId, sectionId);
    if (!updatedTableData) return;

    await blockContentChangedHandler(blockId, sectionId, updatedTableData);
  };

  const handleOnPressEnter = async (event: React.KeyboardEvent<HTMLInputElement>, apiRef: GridApiPro, localValue: string) => {
    if (event.key === 'Enter' && toggledTableSettingsPanel) {
      apiRef.stopCellEditMode({ id, field });
      const allRowIds = apiRef.getAllRowIds();
      const currentRowIndex = allRowIds.findIndex((rowId) => rowId === id);
      const nextRowId = allRowIds[currentRowIndex + 1];

      if (nextRowId) {
        apiRef.setEditCellValue({ id, field, value: localValue });
        apiRef.setRowSelectionModel([nextRowId]);
        setToggledTableSettingsPanel({
          tableApi: {
            selectedModel: apiRef.getCellParams(nextRowId, field),
            tableCallbackDetails: toggledTableSettingsPanel.tableApi.tableCallbackDetails,
          },
        });
        apiRef.startCellEditMode({
          id: nextRowId,
          field,
        });
      }
    }
  };

  return (
    <InputWithTooltips
      params={params}
      blockId={blockId || ''}
      pricingDecimalPlaces={decimalPlaces}
      sectionId={sectionId}
      tooltipTitle={tooltipTitle}
      onInputChange={handleOnInputValueChange}
      onInputBlur={handleOnInputOnBlur}
      onPressEnter={handleOnPressEnter}
    />
  );
};

export default CellInputs;
