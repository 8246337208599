import { Document } from 'services/documents/Document';
import { CINDER_URLS } from 'utils/cinder-urls';
import { URLS } from 'utils/urls';

export function getDocumentLink({
  documentId,
  status,
  editorVersion,
  isLocked,
  previewUrlPrefix,
}: {
  documentId: string;
  status: Document['status'];
  editorVersion: number;
  isLocked: boolean;
  previewUrlPrefix: string;
}) {
  if (editorVersion === 3) {
    if (isLocked) {
      return previewUrlPrefix + documentId;
    }
    switch (status) {
      case 'Draft':
      case 'Sent':
        return URLS.editDocument(documentId);
      case 'Awaiting Signature':
      case 'Won':
      case 'Lost':
        return previewUrlPrefix + documentId;
    }
  }

  return CINDER_URLS.viewSnapshot(documentId);
}
