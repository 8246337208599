import { useCallback } from 'react';
import { ColumnTypeIdentifier, RowTypesIdentifier, TableColumnType } from '../../../grid/reduxStore/table.types';
import { isRowBelongsToTotal } from '../../../../../muiTheme/dataGridUtils';
import { NUMBER_COLUMNS_ALLOWING_PRICING } from './variables';
import CellInputs from './CellInput';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { CustomTableCell } from './components/CustomTableCell';
import { TableNumericCell } from './tableUtils/customCellHelpers';

const NUMERIC_COLUMNS = [ColumnTypeIdentifier.MULTIPLIER, ColumnTypeIdentifier.PRICE, ColumnTypeIdentifier.SUBTOTAL];

export function useApplyCustomRulesToColumns(sectionId: string, blockId: string, pricingDecimalPlaces: number) {
  const setCustomInputToDefaultColumn = (columns: TableColumnType[]): TableColumnType[] => {
    return columns.map((column) => ({
      ...column,
      renderEditCell: (params: GridCellParams) => (
        <CellInputs params={params} blockId={blockId || ''} decimalPlaces={pricingDecimalPlaces} sectionId={sectionId} />
      ),
      renderCell: (params: GridCellParams) =>
        NUMERIC_COLUMNS.includes(column?.columnType) ? (
          <TableNumericCell params={params} columnType={column.columnType} pricingDecimalPlaces={pricingDecimalPlaces} />
        ) : (
          <CustomTableCell params={params} value={params?.value as string} />
        ),
    }));
  };

  const setColumnsColSpanRuleForFooter = (columns: TableColumnType[]): TableColumnType[] => {
    const firstColumnAfterId = 1;

    columns[firstColumnAfterId] = {
      ...columns[firstColumnAfterId],
      colSpan: (_value, row): number => {
        let colSpan = 1;
        if (row.rowType === RowTypesIdentifier.FOOTER) {
          // -1 because the first row is the id, which is not visible
          colSpan = columns.length - 1;
        }
        if (isRowBelongsToTotal(row)) {
          if (columns.length > NUMBER_COLUMNS_ALLOWING_PRICING) {
            colSpan = columns.length - NUMBER_COLUMNS_ALLOWING_PRICING;
          } else {
            colSpan = 1;
          }
        }
        return colSpan;
      },
    };

    return columns;
  };

  const applyCustomRulesToColumns = useCallback(
    (columns: TableColumnType[]): TableColumnType[] => setColumnsColSpanRuleForFooter(setCustomInputToDefaultColumn(columns)),
    []
  );

  return { applyCustomRulesToColumns };
}
