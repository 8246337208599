import { TableColumnType, TableRowType } from '../components/editor/grid/reduxStore/table.types';

export const getSanitizedDataForTableFooter = (row: TableRowType, columns: TableColumnType[], value?: string): TableRowType => {
  const convertedRowToFooterData: TableRowType = { ...row };
  const indexForInitialColumns = 1;
  const indexForAddedColumns = 2;
  const isInitialStateRow = row.field === '';
  const firstColumnAfterId = isInitialStateRow ? indexForAddedColumns : indexForInitialColumns;

  if (isInitialStateRow) {
    delete convertedRowToFooterData['editable'];
    delete convertedRowToFooterData['width'];
    delete convertedRowToFooterData['headerName'];
    delete convertedRowToFooterData['field'];
    columns.slice(firstColumnAfterId).forEach((column) => {
      convertedRowToFooterData[column.field] = '';
    });
  } else {
    Object.keys(row).forEach((key) => {
      if (!['id', 'rowType', '__reorder'].includes(key)) {
        convertedRowToFooterData[key] = value ? value : row[columns[firstColumnAfterId].field];
      }
    });
  }
  return convertedRowToFooterData;
};
