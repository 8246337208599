import { Check, MoreVert } from '@mui/icons-material';
import { Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInsightsContext } from '../InsightsProvider';
import { useShowExactTime } from '../useShowExactTime';
import { HeaderIconButton } from './HeaderIconButton';

export function MenuKebab() {
  const { t } = useTranslation();
  const theme = useTheme();
  const menuAnchorRef = useRef<HTMLButtonElement>(null);
  const { showExactTime, toggleShowExactTime } = useShowExactTime();
  const { hasErrors } = useInsightsContext();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <>
      <HeaderIconButton ref={menuAnchorRef} onClick={openMenu} data-testid="insights-header-menu" disabled={hasErrors}>
        <MoreVert color="primary" />
      </HeaderIconButton>
      <Menu
        anchorEl={menuAnchorRef.current}
        open={isMenuOpen}
        onClose={closeMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <MenuItem dense onClick={toggleShowExactTime} data-test-id="insights-show-exact-dates-option">
          <Check sx={{ visibility: showExactTime ? 'visible' : 'hidden', marginRight: theme.spacing(1.5) }} />
          <Typography variant="body1" color="primary">
            {t('pipeline.insights.show_exact_time')}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
