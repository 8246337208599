import { Skeleton } from '@mui/material';

export function ViewedSectionsChartSkeleton() {
  return (
    <>
      <Skeleton
        variant="rectangular"
        height={40}
        width={500}
        sx={{ marginBottom: '0.5rem' }}
        data-testid="viewed-sections-chart-skeleton"
      />
      <Skeleton variant="rectangular" height={40} width={400} sx={{ marginBottom: '0.5rem' }} />
      <Skeleton variant="rectangular" height={40} width={300} sx={{ marginBottom: '0.5rem' }} />
      <Skeleton variant="rectangular" height={40} width={200} sx={{ marginBottom: '0.5rem' }} />
      <Skeleton variant="rectangular" height={40} width={100} sx={{ marginBottom: '0.5rem' }} />
    </>
  );
}
