import { Box, Tooltip } from '@mui/material';
import IcoCloseX from 'components/icon/icons/IcoCloseX';
import IcoResizeShrink from 'components/icon/icons/IcoResizeShrink';
import { useTranslation } from 'react-i18next';
import { DocumentPrice } from './DocumentPrice';
import { DocumentTitle } from './DocumentTitle';
import { EditButton } from './EditButton';
import { HeaderIconButton } from './HeaderIconButton';
import { MenuKebab } from './MenuKebab';
import { NavigationButtons } from './NavigationButtons';
import { PreviewButton } from './PreviewButton';

export interface ExpandedHeaderProps {
  onClose: ({ isExpanded }: { isExpanded: boolean }) => void;
  onToggleExpand: () => void;
}

export function ExpandedHeader({ onClose, onToggleExpand }: ExpandedHeaderProps) {
  const { t } = useTranslation();

  return (
    <>
      <Box flex="1 1 auto" flexDirection="column" marginRight="0.5rem" overflow="hidden">
        <DocumentTitle variant="h6" />
        <DocumentPrice />
      </Box>
      <Box flex="0 0 auto" display="flex" alignItems="center" gap="0.75rem">
        <PreviewButton isBig />
        <EditButton isBig />
        <NavigationButtons />
        <Tooltip title={t('pipeline.insights.header.resize_shrink_tooltip')}>
          <HeaderIconButton data-testid="insights-resize-button-shrink" onClick={onToggleExpand}>
            <IcoResizeShrink />
          </HeaderIconButton>
        </Tooltip>
        <MenuKebab />
        <HeaderIconButton data-testid="insights-dismiss-button" onClick={() => onClose({ isExpanded: true })}>
          <IcoCloseX />
        </HeaderIconButton>
      </Box>
    </>
  );
}
