import { TFunction } from 'i18next';
import { useCurrentUser } from 'providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { useShowExactTime } from '../useShowExactTime';

function getTimeDifference({ date, t }: { date: string; t: TFunction }): string {
  const dateObj = new Date(date);
  const today = Date.now();

  const diffInSeconds = Math.round((today - dateObj.getTime()) / 1000);
  if (diffInSeconds < 60) return t('pipeline.insights.activity_date_seconds_ago', { seconds: diffInSeconds });
  const diffInMinutes = Math.round(diffInSeconds / 60);
  if (diffInMinutes < 60) return t('pipeline.insights.activity_date_minutes_ago', { minutes: diffInMinutes });
  const diffInHours = Math.round(diffInMinutes / 60);
  if (diffInHours < 24) return t('pipeline.insights.activity_date_hours_ago', { hours: diffInHours });
  const diffInDays = Math.round(diffInHours / 24);
  if (diffInDays < 30) return t('pipeline.insights.activity_date_days_ago', { days: diffInDays });
  const diffInMonths = Math.round(diffInDays / 30);
  if (diffInMonths < 12) return t('pipeline.insights.activity_date_months_ago', { months: diffInMonths });
  const diffInYears = Math.round(diffInMonths / 12);
  return t('pipeline.insights.activity_date_years_ago', { years: diffInYears });
}

type FormattedActivityDate = {
  dateOrRelativeDate: string;
  time: string;
};

export function useFormatActivityDate() {
  const { t, i18n } = useTranslation();
  const { data: currentUserInfo } = useCurrentUser();
  const { showExactTime } = useShowExactTime();

  const userTimezone = currentUserInfo.timezone || 'UTC';

  const formatRelativeTime = (date: string): FormattedActivityDate => {
    return {
      dateOrRelativeDate: getTimeDifference({ date, t }),
      time: '',
    };
  };

  const formatExactTime = (date: string): FormattedActivityDate => {
    const dateObj = new Date(date);
    return {
      dateOrRelativeDate: dateObj.toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric', timeZone: userTimezone }),
      time: dateObj.toLocaleTimeString(i18n.language || 'en-US', { hour: 'numeric', minute: 'numeric', timeZone: userTimezone }),
    };
  };

  return { formatActivityDate: showExactTime ? formatExactTime : formatRelativeTime };
}
