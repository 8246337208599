import { useTranslation } from 'react-i18next';
import { setSaveStatus } from '../components/editor/grid/reduxStore/saveStatusSlice';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { openNotification } from 'components';
import { DocumentSaveStatus } from '../components/editor/shared/models/DocumentSaveStatus';
import { ApiEditorRepository } from 'services/repositories/implementations/ApiEditorRepository';
import { UpdateDocumentPayloadByContentId } from 'services/documents/DocumentRepository';
import { ErrorCode } from '../services/socket/SocketEvents';
import { QueryKeys } from 'utils/queryKeys';
import { useAppDispatch } from '../components/editor/grid/reduxStore/Store';

interface MutationContext {
  previousData: unknown;
}

export default function useUpdateVariables() {
  const editorRepository = new ApiEditorRepository();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return useMutation<void, AxiosError, UpdateDocumentPayloadByContentId, MutationContext>({
    mutationFn: async (payload) => {
      dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVING }));
      await editorRepository.patchContent(payload);
    },
    onSuccess: (_, payload) => {
      if (!payload.contentId) return;
      const contentId = payload.contentId;
      queryClient.setQueryData(QueryKeys.getDocumentById(contentId), (oldData: UpdateDocumentPayloadByContentId | undefined) => ({
        ...oldData,
        ...payload,
      }));
      queryClient.invalidateQueries(QueryKeys.getDocumentById(contentId));
      dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVED }));
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries(QueryKeys.getDocumentById(newData.contentId));
      const previousData = queryClient.getQueryData(QueryKeys.getDocumentById(newData.contentId));

      queryClient.setQueryData(QueryKeys.getDocumentById(newData.contentId), (old: any) => ({
        ...old,
        ...newData,
      }));

      return { previousData };
    },
    onError: (error, newData, context) => {
      const { status } = error.response || {};
      let errorCode;

      if (status === 423) {
        errorCode = ErrorCode.LOCKED;
        openNotification({
          title: t('error'),
          description: t('document.menu.locked_error'),
          type: 'error',
        });
      }

      dispatch(setSaveStatus({ status: DocumentSaveStatus.NOT_SAVED, errCode: errorCode }));

      if (context?.previousData) {
        queryClient.setQueryData(QueryKeys.getDocumentById(newData.contentId), context.previousData);
      }
    },
    onSettled: (_, __, newData) => {
      queryClient.invalidateQueries(QueryKeys.getDocumentById(newData.contentId));
    },
  });
}
