import { Box, Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Activities } from './activities/Activities';
import { Analytics } from './analytics/Analytics';
import { ContentNotAvailable } from './ContentNotAvailable';
import { useInsightsContext } from './InsightsProvider';

export function InsightsExpandedContent() {
  const { t } = useTranslation();
  const { hasErrors } = useInsightsContext();

  if (hasErrors) {
    return <ContentNotAvailable isExpanded />;
  }

  return (
    <Box padding="1.5rem 3.5rem" display="grid" gap="1.5rem" gridTemplateColumns="1fr 1fr">
      <Card elevation={0} sx={{ padding: '1.5rem' }}>
        <Typography variant="h4" fontWeight="bold" paddingBottom="1.5rem">
          {t('pipeline.insights.tab_activity')}
        </Typography>
        <Activities />
      </Card>
      <Card elevation={0} sx={{ padding: '1.5rem' }}>
        <Analytics />
      </Card>
    </Box>
  );
}
