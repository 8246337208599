import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BlockSides } from '../../GridDndEditor/Block/Helper/BlockSides';
import { GridBlockType } from '../../shared/gridBlockType';
import { SectionContentType } from '../../Sections/Section.model';
import { TableSettingsTypes } from '../../SidePanel/content/TableSettings/types';

interface PaddingStyleType {
  paddingTop?: number;
  paddingBottom?: number;
  paddingRight?: number;
  paddingLeft?: number;
  borderWidth?: number;
}

export interface BlockSettings {
  updatedByUserId: number;
  blockId: string;
  borderStyle?: string;
  borderLeft?: number;
  borderRight?: number;
  borderTop?: number;
  borderBottom?: number;
  borderRadius?: number;
  borderColor?: string;
  backgroundColor?: string;
  opacity?: number;
  imageAlt?: string;
  imageLink?: string;
  paddingTop?: number;
  paddingBottom?: number;
  paddingRight?: number;
  paddingLeft?: number;
  borderWidth?: number;
  currentBorderSide?: BlockSides[];
  imageAspectRatioLock?: boolean;
}

export interface BlockStyleSettingsState {
  blockStyle: { [key: string]: BlockSettings };
  toggledDesignSettingModelType: GridBlockType | null;
  activeTableSettingsPanel: TableSettingsTypes | null;
  activeTotalToggle: boolean | null;
  isAdvancedSpacingModelOpen: boolean;
}

const initialState: BlockStyleSettingsState = {
  blockStyle: {},
  toggledDesignSettingModelType: null,
  activeTableSettingsPanel: null,
  activeTotalToggle: null,
  isAdvancedSpacingModelOpen: false,
};

const blockStyleSettingsSlice = createSlice({
  name: 'blockStyleSettings',
  initialState,
  reducers: {
    loadBlockStyleSettings: (state, action: PayloadAction<{ sections: SectionContentType[]; defaultBlockStyle: BlockSettings }>) => {
      const { sections, defaultBlockStyle } = action.payload;
      sections.forEach((section) => {
        section.blocks.forEach((block) => {
          const { blockId, blockSettings } = block;
          if (blockId && !state.blockStyle[blockId]) {
            state.blockStyle[blockId] = {
              ...defaultBlockStyle,
              ...blockSettings,
              blockId,
              borderWidth: blockSettings?.borderLeft || 0,
              currentBorderSide: [BlockSides.ALL_SIDES],
            };
          }
        });
      });
    },
    addBlockSettings: (state, action: PayloadAction<{ blockId: string; blockSettings: BlockSettings }>) => {
      const { blockId, blockSettings } = action.payload;
      state.blockStyle[blockId] = { ...blockSettings, blockId };
    },
    deleteBlockSettings: (state, action: PayloadAction<{ blockId: string }>) => {
      const { blockId } = action.payload;
      delete state.blockStyle[blockId];
    },
    updateBlockSettings: (state, action: PayloadAction<{ blockId: string; blockSettings: BlockSettings }>) => {
      const { blockId, blockSettings } = action.payload;
      if (state.blockStyle[blockId]) {
        state.blockStyle[blockId] = { ...state.blockStyle[blockId], ...blockSettings };
      }
    },
    setBlockBackgroundColor: (state, action: PayloadAction<{ blockId: string; backgroundColor: string }>) => {
      const { blockId, backgroundColor } = action.payload;
      if (state.blockStyle[blockId]) {
        state.blockStyle[blockId].backgroundColor = backgroundColor;
      }
    },
    setBlockBorderColor: (state, action: PayloadAction<{ blockId: string; borderColor: string }>) => {
      const { blockId, borderColor } = action.payload;
      if (state.blockStyle[blockId]) {
        state.blockStyle[blockId].borderColor = borderColor;
      }
    },
    setBlockBorderSide: (state, action: PayloadAction<{ blockId: string; updatedStyle: Partial<BlockSettings> }>) => {
      const { blockId, updatedStyle } = action.payload;
      if (state.blockStyle[blockId]) {
        state.blockStyle[blockId] = { ...state.blockStyle[blockId], ...updatedStyle };
      }
    },
    setBorderRadius: (state, action: PayloadAction<{ blockId: string; borderRadius: number }>) => {
      const { blockId, borderRadius } = action.payload;
      if (state.blockStyle[blockId]) {
        state.blockStyle[blockId].borderRadius = borderRadius;
      }
    },
    setBorderWidth: (state, action: PayloadAction<{ blockId: string; updatedStyle: Partial<BlockSettings> }>) => {
      const { blockId } = action.payload;
      if (state.blockStyle[blockId]) {
        state.blockStyle[blockId] = { ...state.blockStyle[blockId], ...action.payload.updatedStyle };
      }
    },
    setToggledDesignSettingModelType: (state, action: PayloadAction<{ type: GridBlockType | null }>) => {
      state.toggledDesignSettingModelType = action.payload.type;
    },
    setActiveTableSettingsPanel: (state, action: PayloadAction<{ type: TableSettingsTypes | null }>) => {
      state.activeTableSettingsPanel = action.payload.type;
    },
    setActiveTotalToggle: (state, action: PayloadAction<{ activeTotalToggle: boolean | null }>) => {
      state.activeTotalToggle = action.payload.activeTotalToggle;
    },
    setIsAdvancedSpacingModelOpen: (state, action: PayloadAction<boolean>) => {
      state.isAdvancedSpacingModelOpen = action.payload;
    },
    resetAllPanels: (state) => {
      state.toggledDesignSettingModelType = null;
      state.activeTableSettingsPanel = null;
    },
    setPaddingValues: (state, action: PayloadAction<{ blockId: string; paddingStyle: PaddingStyleType }>) => {
      const { blockId, paddingStyle } = action.payload;
      if (state.blockStyle[blockId]) {
        state.blockStyle[blockId] = { ...state.blockStyle[blockId], ...paddingStyle };
      }
    },
    setAspectRatio: (state, action: PayloadAction<{ blockId: string; imageAspectRatioLock: boolean }>) => {
      const { blockId, imageAspectRatioLock } = action.payload;
      if (state.blockStyle[blockId]) {
        state.blockStyle[blockId].imageAspectRatioLock = imageAspectRatioLock;
      }
    },
  },
});

export const {
  loadBlockStyleSettings,
  addBlockSettings,
  deleteBlockSettings,
  updateBlockSettings,
  setBlockBackgroundColor,
  setBlockBorderColor,
  setBlockBorderSide,
  setActiveTotalToggle,
  setBorderRadius,
  setBorderWidth,
  setIsAdvancedSpacingModelOpen,
  setPaddingValues,
  resetAllPanels,
  setActiveTableSettingsPanel,
  setToggledDesignSettingModelType,
  setAspectRatio,
} = blockStyleSettingsSlice.actions;

export const blockStyleSettings = blockStyleSettingsSlice.reducer;
