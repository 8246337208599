import { useCallback, useState } from 'react';
import { GridCellParams, GridCellModesModel, GridCellModes } from '@mui/x-data-grid-pro';

export const useTableCellModes = () => {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const handleCellClick = useCallback((params: GridCellParams) => {
    if (!params.isEditable) {
      return;
    }

    setCellModesModel((prevModel) => {
      const allCellsInViewMode = Object.entries(prevModel).reduce(
        (tableAcc, [rowId, rowFields]) => ({
          ...tableAcc,
          [rowId]: Object.keys(rowFields).reduce(
            (fieldAcc, fieldName) => ({
              ...fieldAcc,
              [fieldName]: { mode: GridCellModes.View },
            }),
            {}
          ),
        }),
        {}
      );

      const clickedRowId = params.id;
      const clickedColumnName = params.field;

      return {
        ...allCellsInViewMode,
        [clickedRowId]: {
          ...Object.keys(prevModel[clickedRowId] || {}).reduce(
            (columns, columnName) => ({
              ...columns,
              [columnName]: { mode: GridCellModes.View },
            }),
            {}
          ),
          [clickedColumnName]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  return {
    cellModesModel,
    handleCellClick,
    handleCellModesModelChange,
  };
};
