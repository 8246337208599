import { Tooltip, Typography } from '@mui/material';
import IcoEdit from 'components/icon/icons/IcoEdit';
import { useTranslation } from 'react-i18next';
import { useInsightsContext } from '../InsightsProvider';
import { HeaderIconButton } from './HeaderIconButton';
import { HeaderIconButtonBig } from './HeaderIconButtonBig';
import { useInsightsPanelHeaderNavigation } from './useInsightsPanelHeaderNavigation';

interface EditButtonProps {
  isBig?: boolean;
}

export function EditButton({ isBig = false }: EditButtonProps) {
  const { t } = useTranslation();
  const { getCurrentDocument, hasErrors } = useInsightsContext();
  const { navigateToEditor } = useInsightsPanelHeaderNavigation();
  const isLocked = getCurrentDocument()?.isLocked;

  const IconButtonComponent = isBig ? HeaderIconButtonBig : HeaderIconButton;

  return (
    <Tooltip
      title={
        hasErrors ? undefined : isLocked ? t('pipeline.insights.header.edit_locked_tooltip') : t('pipeline.insights.header.edit_tooltip')
      }
    >
      <span>
        <IconButtonComponent
          disabled={isLocked || hasErrors}
          onClick={navigateToEditor}
          data-testid="insights-edit-button"
          sx={{
            backgroundColor: 'primary.main',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#001118',
              color: '#FFFFFF',
            },
            '&.Mui-disabled': {
              backgroundColor: '#00283A1F',
              color: '#00283A61',
            },
          }}
        >
          <IcoEdit />
          {isBig && <Typography variant="labelMedium">{t('edit')}</Typography>}
        </IconButtonComponent>
      </span>
    </Tooltip>
  );
}
