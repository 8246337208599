import { useQuery } from 'react-query';
import { apiDocumentRepository } from 'services/documents/ApiDocumentRepository';
import { QueryKeys } from 'utils/queryKeys';

export const useGetContentStyleByContentId = (contentId: string) => {
  return useQuery({
    queryKey: QueryKeys.getContentStyleById(contentId),
    queryFn: () => apiDocumentRepository.getContentStyleByContentId(contentId),
    retry: 2,
  });
};
