import { AxiosError } from 'axios';
import { openNotification } from 'components';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { apiDocumentRepository } from 'services/documents/ApiDocumentRepository';
import { UpdateDocumentPayload } from 'services/documents/DocumentRepository';
import { QueryKeys } from 'utils/queryKeys';
import { setSaveStatus } from '../components/editor/grid/reduxStore/saveStatusSlice';
import { useAppDispatch } from '../components/editor/grid/reduxStore/Store';
import { DocumentSaveStatus } from '../components/editor/shared/models/DocumentSaveStatus';
import { ErrorCode } from '../services/socket/SocketEvents';

export default function useUpdateDocumentMutation() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: UpdateDocumentPayload) => {
      dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVING }));
      return apiDocumentRepository.updateDocument(payload);
    },
    onSuccess: async (_, payload) => {
      await queryClient.invalidateQueries(QueryKeys.getDocumentById(payload.documentId));
      dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVED }));
    },
    onError: (error: unknown) => {
      if (error instanceof AxiosError && error.response?.status === 423) {
        dispatch(setSaveStatus({ status: DocumentSaveStatus.NOT_SAVED, errCode: ErrorCode.LOCKED }));
      } else {
        openNotification({
          title: t('error'),
          description: t('document.menu.update_error'),
          type: 'error',
        });
      }
    },
  });
}
