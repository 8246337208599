import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { IconButton, Tooltip } from '@mui/material';
import { openNotification } from 'components/notification';
import { openToast } from 'components/toast';
import { useTranslation } from 'react-i18next';
import { Document } from 'services/documents/Document';
import { useTrashRestore } from './useTrashRestore';

export type TrashDocumentActionProps = {
  documentToTrash: Document;
};

export function TrashDocumentAction({ documentToTrash }: TrashDocumentActionProps) {
  const { t } = useTranslation();
  const { handleProceed, handleUndo } = useTrashRestore({
    listType: 'Documents',
    document: documentToTrash,
  });

  const onSuccess = async () => {
    openToast({
      message: t('pipeline.tabs.document_list.action_message'),
      onUndo: async () => handleUndo({}),
    });
  };

  const onFailure = () => {
    openNotification({
      type: 'error',
      title: t('pipeline.error.delete_error_msg'),
      description: t('pipeline.error.error_description'),
      placement: 'top',
    });
  };

  return (
    <Tooltip title={t('pipeline.tabs.document_list.more_actions.trash_document_tooltip')}>
      <IconButton onClick={() => handleProceed({ onSuccess, onFailure })} data-testid={`trash-area-${documentToTrash.title}`}>
        <DeleteIcon color="primary" />
      </IconButton>
    </Tooltip>
  );
}
