import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import AsyncCreatableSelect, { AsyncCreatableProps } from 'react-select/async-creatable';
import { Person } from 'services/entities/PersonEntity';
import { isValidEmail } from 'utils/isValidEmail';
import './styles.less';
import { baseComponents, baseStyles, theme } from 'pages/proposals/share/blocks/form/email-picker/shared';
import { EmailPickerOption } from 'pages/proposals/share/blocks/form/email-picker';
import { AdditionalMultiValueLabelData } from 'pages/proposals/share/blocks/form/email-picker/types';
import { convertToOption, createNewOption } from 'pages/proposals/share/blocks/form/email-picker/utils';
import { apiDocumentRepository } from 'services/documents/ApiDocumentRepository';

type CreatableSelectProps = AsyncCreatableProps<EmailPickerOption, true, any>;
interface BaseProps extends CreatableSelectProps {
  value: MultiValue<EmailPickerOption>;
  onChange: CreatableSelectProps['onChange'];
}

export interface CreatableEmailPickerProps extends BaseProps, AdditionalMultiValueLabelData {}

type OnInputChange = CreatableEmailPickerProps['onInputChange'];

type LoadOptionsFunc = (accountId: number) => CreatableSelectProps['loadOptions'];

export const loadOptions: LoadOptionsFunc = (accountId) => (inputValue: string, callback) => {
  apiDocumentRepository
    .getSignees(accountId, { searchText: inputValue || undefined, offset: 0, limit: 10 })
    .then((response) => {
      callback(
        response.signees.map(({ email, uid, firstName, fullName, lastName, id, signeeType }) =>
          convertToOption({ email, firstName, fullName, id, lastName, uid, type: signeeType === 'PERSON' ? 'contact' : 'user' })
        )
      );
    })
    .catch(() => {
      callback([]);
    });
};

export const CreatableEmailPicker: React.FC<CreatableEmailPickerProps> = ({ additionalData, onChange, value = [], ...props }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');

  const clearInputValue = () => setInputValue('');

  const inputBlurHandler = (latestInputValue: string) => {
    if (isValidEmail(latestInputValue) && onChange) {
      const option = createNewOption(latestInputValue);
      onChange([...value, option], { action: 'create-option', option });
      clearInputValue();
    }
  };

  const onInputChange: OnInputChange = (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case 'input-change':
        return setInputValue(newValue);
      case 'set-value':
        return clearInputValue();
      case 'input-blur':
        return inputBlurHandler(actionMeta.prevInputValue);
    }
  };

  const onCreate = (created: Person) => {
    const option = value.find((v) => v.value === created.email);
    if (option && onChange) {
      const updatedOption = convertToOption({ ...created });
      onChange(
        value.map((v) => {
          return v.value === option.value ? { ...option, id: created.id } : v;
        }),
        { action: 'select-option', option: updatedOption }
      );
    }
  };

  return (
    <AsyncCreatableSelect
      isMulti
      components={{
        ...baseComponents,
        MultiValueLabel: (multiValueLabelProps) => (
          <baseComponents.MultiValueLabel {...multiValueLabelProps} additionalData={{ ...additionalData, onCreate }} />
        ),
      }}
      {...props}
      formatCreateLabel={() => t('share.email_picker.add')}
      noOptionsMessage={() => t('share.email_picker.empty_message')}
      isValidNewOption={(inputValue) => isValidEmail(inputValue)}
      styles={baseStyles}
      theme={theme}
      openMenuOnClick={false}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onChange={onChange}
      value={value}
      closeMenuOnSelect={false}
      getNewOptionData={(inputValue) => {
        return createNewOption(inputValue);
      }}
      isClearable={false}
    />
  );
};
