import { useCallback } from 'react';
import { ColumnTypeIdentifier, TableColumnType, TableRowType } from '../../../grid/reduxStore/table.types';
import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import PreviewEditableCellWrapper from './PreviewEditableCellWrapper';
import InputWithTooltips from '../Table/components/InputWithTooltips';

export function useApplyCustomRulesToColumnsInPreview(blockId: string, pricingDecimalPlaces: number) {
  const applyCustomRulesToColumnsInPreview = useCallback(
    (columns: TableColumnType[], updateRows: (updatedRow: TableRowType) => void, isDocumentLocked: boolean): TableColumnType[] => {
      let updatedColumns: TableColumnType[] = [...columns];
      updatedColumns = setReviewCustomInputToDefaultColumn(updatedColumns, updateRows, isDocumentLocked);

      return updatedColumns;
    },
    []
  );

  const setReviewCustomInputToDefaultColumn = (
    columns: TableColumnType[],
    updateRows: (updatedRow: TableRowType) => void,
    isDocumentLocked: boolean
  ): TableColumnType[] => {
    return columns.map((column) => ({
      ...column,
      renderEditCell: (params: GridCellParams) => (
        <InputWithTooltips params={params} blockId={blockId || ''} pricingDecimalPlaces={pricingDecimalPlaces} />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <PreviewEditableCellWrapper
          updateRows={updateRows}
          params={params}
          isDocumentLocked={isDocumentLocked}
          columnType={params.colDef['columnType'] as ColumnTypeIdentifier}
          pricingDecimalPlaces={pricingDecimalPlaces}
        />
      ),
    }));
  };

  return { applyCustomRulesToColumnsInPreview };
}
