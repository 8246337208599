import { useQuery } from 'react-query';
import { ViewedSectionsResponse } from 'services/activities/ActivitiesRepository';
import { ApiActivitiesRepository } from 'services/activities/ApiActivitiesRepository';
import { useInsightsContext } from '../InsightsProvider';

export function useQueryViewedSections() {
  const { documentId } = useInsightsContext();
  const { data, isLoading } = useQuery<ViewedSectionsResponse>(['getViewedSections', documentId], () =>
    ApiActivitiesRepository.getViewedSections(documentId)
  );

  const viewedSections = !data ? [] : data;

  return {
    viewedSections,
    isLoading,
  };
}
