import { Box, Tooltip } from '@mui/material';
import IcoCloseX from 'components/icon/icons/IcoCloseX';
import IcoResizeExpand from 'components/icon/icons/IcoResizeExpand';
import { useTranslation } from 'react-i18next';
import { DocumentTitle } from './DocumentTitle';
import { EditButton } from './EditButton';
import { HeaderIconButton } from './HeaderIconButton';
import { MenuKebab } from './MenuKebab';
import { NavigationButtons } from './NavigationButtons';
import { PreviewButton } from './PreviewButton';

export interface ShrunkHeaderProps {
  onClose: ({ isExpanded }: { isExpanded: boolean }) => void;
  onToggleExpand: () => void;
}

export function ShrunkHeader({ onClose, onToggleExpand }: ShrunkHeaderProps) {
  const { t } = useTranslation();

  return (
    <>
      <Box flex="1 1 auto" marginRight="0.5rem" overflow="hidden">
        <DocumentTitle variant="h6" />
      </Box>
      <Box flex="0 0 auto" display="flex" alignItems="center" gap="0.75rem">
        <PreviewButton />
        <EditButton />
        <NavigationButtons />
        <Tooltip title={t('pipeline.insights.header.resize_expand_tooltip')}>
          <HeaderIconButton data-testid="insights-resize-button-expand" onClick={onToggleExpand}>
            <IcoResizeExpand />
          </HeaderIconButton>
        </Tooltip>
        <MenuKebab />
        <HeaderIconButton data-testid="insights-dismiss-button" onClick={() => onClose({ isExpanded: false })}>
          <IcoCloseX />
        </HeaderIconButton>
      </Box>
    </>
  );
}
