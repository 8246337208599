import { useState } from 'react';
import { Document } from 'services/documents/Document';

export const NUMBER_OF_DOCUMENTS_PER_PAGE = 50;

type UsePaginationProps = {
  documentList: Document[];
  currentPageFilter?: string;
};

export function usePagination({ documentList, currentPageFilter }: UsePaginationProps) {
  const [currentPage, setCurrentPage] = useState(
    currentPageFilter && !isNaN(parseInt(currentPageFilter)) ? parseInt(currentPageFilter) : 1
  );

  const indexOfLastDoc = currentPage * NUMBER_OF_DOCUMENTS_PER_PAGE;
  const indexOfFirstDoc = indexOfLastDoc - NUMBER_OF_DOCUMENTS_PER_PAGE;
  const paginatedDocs = documentList.slice(indexOfFirstDoc, indexOfLastDoc);
  const lastPage = Math.ceil(documentList.length / NUMBER_OF_DOCUMENTS_PER_PAGE);

  return { currentPage, setCurrentPage, paginatedDocs, lastPage };
}
