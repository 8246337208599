import { useTableRowManipulation } from '../../../../SidePanel/content/TableSettings/RowSettings/useTableRowManipulation';
import Checkbox from '@mui/material/Checkbox';

const OptionalRowInput = ({ id, isRowOptional }: { id: string; isRowOptional: boolean }) => {
  const { toggleRowProperty } = useTableRowManipulation();

  const label = { inputProps: { 'aria-label': 'Optional row' } };
  const handleCheckboxChange = async () => {
    await toggleRowProperty('isRowOptional', !isRowOptional, id);
  };

  return (
    <Checkbox
      data-testid="row-optional-chekbox"
      className="py-row-optional"
      {...label}
      onChange={handleCheckboxChange}
      checked={isRowOptional}
    />
  );
};

export default OptionalRowInput;
