import { createContext, useContext } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { ActivityResponse } from 'services/activities/ActivitiesRepository';
import { useInsightsContext } from '../InsightsProvider';
import { Activity } from './types';
import { useQueryActivities } from './useQueryActivities';

export type ActivitiesContext = {
  activities: Activity[];
  isLoading: boolean;
};

export const ActivitiesContext = createContext<ActivitiesContext | null>(null);
export const useActivitiesContext = () => {
  const context = useContext<ActivitiesContext | null>(ActivitiesContext);

  if (!context) {
    throw new Error('useActivitiesContext must be used within an ActivitiesProvider');
  }

  return context;
};

export const ActivitiesProvider = ({ children }: { children: React.ReactNode }) => {
  const { documentId } = useInsightsContext();
  const { activities, isLoading } = useQueryActivities({ documentId });
  const { t } = useTranslation();

  const activitiesEntities = activities.map((activity, index) => ({
    id: index,
    type: activity.type,
    title: getActivityTitle(activity, t),
    details: getActivityDetails(activity),
    date: new Date(activity.createdAt).toISOString(),
    isViewedByMultipleUsers: false,
    who: 'user' in activity ? [activity.user.name] : [],
  }));

  return <ActivitiesContext.Provider value={{ activities: activitiesEntities, isLoading }}>{children}</ActivitiesContext.Provider>;
};

function getActivityTitle(activity: ActivityResponse, t: TFunction) {
  switch (activity.type) {
    case 'document-created':
      return t('pipeline.insights.document_created_title', { user: activity.user.name });
    case 'document-won':
      return t('pipeline.insights.document_won_title');
    case 'document-lost':
      return t('pipeline.insights.document_lost_title');
    case 'document-sent':
      return t('pipeline.insights.document_sent_title');
    case 'document-trashed':
      return t('pipeline.insights.document_trashed_title');
    default:
      return '';
  }
}

function getActivityDetails(activity: ActivityResponse) {
  switch (activity.type) {
    default:
      return undefined;
  }
}
