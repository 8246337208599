import { ProspectType } from 'services/repositories/interfaces/ProspectRepository';

interface BaseProspect {
  id: number;
  name: string;
  uid: string;
}

interface CompanyProspect extends BaseProspect {
  type: ProspectType.Company;
  contact: {
    id: number;
    name: string;
    email: string;
  } | null;
}

interface ContactProspect extends BaseProspect {
  type: ProspectType.Person;
  contact: {
    id: number;
    name: string;
    email: string;
  } | null;
}

export type DocumentProspect = CompanyProspect | ContactProspect;

export enum DocumentStatus {
  Won = 'WON',
  Lost = 'LOST',
  Draft = 'DRAFT',
}

export interface DocumentEntity {
  id: string;
  title: string;
  price: number;
  status: DocumentStatus;
  isLocked: boolean;
  company: { id: number; name: string } | null;
  assignedUser: { id: number; name: string } | null;
  prospect: DocumentProspect | null;
  isDocumentBelongsToSameAccount: boolean;
  contentId: string;
}

export interface SigneeEntity {
  uid: string;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  signeeType: 'USER' | 'PERSON';
  fullName: string;
}
