import { useEffect, useState } from 'react';
import { apiDocumentRepository } from 'services/documents/ApiDocumentRepository';
import { Document } from 'services/documents/Document';

export function useDocuments({ onFetchError }: { onFetchError: () => void }) {
  const [documentList, setDocumentList] = useState<Document[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const fetchDocumentList = async () => {
    try {
      const response = await apiDocumentRepository.list();
      const fetchedDocumentList = response.documents;

      setDocumentList(fetchedDocumentList);
      setIsLoading(false);
    } catch (error) {
      onFetchError();
    }
  };

  useEffect(() => {
    fetchDocumentList();
  }, []);

  const refreshDocumentList = async () => {
    setIsRefreshing(true);
    setIsLoading(true);
    await fetchDocumentList();
    setIsRefreshing(false);
  };

  return {
    documentList,
    isLoading,
    isRefreshing,
    refreshDocumentList,
    setDocumentList,
  };
}
