import { useEffect, useState } from 'react';
import { Document } from 'services/documents/Document';
import { useDocumentsContext } from './DocumentsProvider';

type Params = {
  filterTrash?: boolean;
  filterTerm?: string;
  filterStatus?: string;
};

const filterBySearchTerm = (documentList: Document[], filterTerm: string) => {
  const lowerCaseFilterTerm = filterTerm.toLowerCase();
  return (documentList = documentList.filter((document) => {
    return (
      document.companyName?.toLowerCase().includes(lowerCaseFilterTerm) ||
      document.title.toLowerCase().includes(lowerCaseFilterTerm) ||
      document.assigneeUserFirstName?.toLowerCase().includes(lowerCaseFilterTerm) ||
      document.assigneeUserLastName?.toLowerCase().includes(lowerCaseFilterTerm)
    );
  }));
};

const filterByStatus = (documentList: Document[], filterStatus: string) => {
  return (documentList = documentList.filter((item) => filterStatus.includes(item.status)));
};

const filterTrashDocuments = (documentList: Document[], filterTrash: boolean) => {
  if (filterTrash) {
    const currentDate = Date.now();
    return (documentList = documentList.filter((document) => {
      if (document.deleted_at) {
        const deletedAtDate = Date.parse(document.deleted_at);
        return deletedAtDate > currentDate;
      } else {
        return false;
      }
    }));
  } else {
    return (documentList = documentList.filter((document) => !document.deleted_at));
  }
};

export function useFilteredDocuments({ filterTrash = false, filterTerm = '', filterStatus = '' }: Params) {
  const { documentList: originalDocumentList } = useDocumentsContext();
  const [filteredDocumentList, setFilteredDocumentList] = useState<Document[]>([]);

  useEffect(() => {
    let documentList = originalDocumentList;
    if (filterTerm) {
      documentList = filterBySearchTerm(documentList, filterTerm);
    }
    if (filterStatus && !filterStatus.includes('All')) {
      documentList = filterByStatus(documentList, filterStatus);
    }
    documentList = filterTrashDocuments(documentList, filterTrash);
    setFilteredDocumentList(documentList);
  }, [originalDocumentList, filterTerm, filterStatus, filterTrash]);

  return {
    filteredDocumentList,
    setFilteredDocumentList,
  };
}
