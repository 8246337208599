import { useState } from 'react';
import { Document } from 'services/documents/Document';
import { DocumentAvatar } from '../components/documents-list/DocumentAvatar';
import { DocumentCreatedDate } from '../components/documents-list/DocumentCreatedDate';
import { DocumentListItem } from '../components/documents-list/DocumentListItem';
import { DocumentRightItems } from '../components/documents-list/DocumentRightItems';
import { DocumentTitle } from '../components/documents-list/DocumentTitle';
import { DocumentStatusChip } from '../components/DocumentStatusChip';
import { getAssignedProspect } from '../utils/getAssignedProspect';
import { useFormatDateTime } from '../utils/useFormatDateTime';
import { useGoToDocument } from '../utils/useGoToDocument';
import { RestoreDocumentAction } from './RestoreDocumentAction';

interface TrashListItemProps {
  document: Document;
}

export function TrashListItem({ document }: TrashListItemProps) {
  const [isHovered, setIsHovered] = useState(false);
  const { formatDocumentDateTime } = useFormatDateTime();
  const { goToDocument } = useGoToDocument({ document });

  return (
    <DocumentListItem dataTestId={`list-item-${document.id}`} status={document.status} setIsHovered={setIsHovered} onClick={goToDocument}>
      <DocumentTitle title={document.title} subtitle={getAssignedProspect(document)} isV2={document.editorVersion === 2} />
      <DocumentCreatedDate date={formatDocumentDateTime(document.created_at)} show={!isHovered} />
      <DocumentAvatar document={document} show={!isHovered} />
      <DocumentRightItems>
        <DocumentStatusChip status={document.status} />
        {isHovered && <RestoreDocumentAction documentToRestore={document} />}
      </DocumentRightItems>
    </DocumentListItem>
  );
}
