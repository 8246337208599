import { Skeleton, Typography } from '@mui/material';
import { useInsightsContext } from '../InsightsProvider';
import { useGetDocumentPrice } from './useGetDocumentPrice';

export function DocumentPrice() {
  const { documentId, hasErrors } = useInsightsContext();
  const { price, isLoading } = useGetDocumentPrice(documentId);

  if (isLoading) {
    return <Skeleton variant="text" width={40} data-testid="document-price-skeleton" />;
  }

  return hasErrors ? null : (
    <Typography variant="body2" data-testid="insights-header-price">
      {price}
    </Typography>
  );
}
