import { Tooltip, Typography } from '@mui/material';
import IcoInsightsPreview from 'components/icon/icons/IcoInsightsPreview';
import { useTranslation } from 'react-i18next';
import { useInsightsContext } from '../InsightsProvider';
import { HeaderIconButton } from './HeaderIconButton';
import { HeaderIconButtonBig } from './HeaderIconButtonBig';
import { useInsightsPanelHeaderNavigation } from './useInsightsPanelHeaderNavigation';

interface PreviewButtonProps {
  isBig?: boolean;
}

export function PreviewButton({ isBig = false }: PreviewButtonProps) {
  const { t } = useTranslation();
  const { documentId, hasErrors } = useInsightsContext();
  const { navigateToPreview } = useInsightsPanelHeaderNavigation();

  const IconButtonComponent = isBig ? HeaderIconButtonBig : HeaderIconButton;

  return (
    <Tooltip title={t('pipeline.insights.header.preview_tooltip')}>
      <span>
        <IconButtonComponent
          disabled={hasErrors || !documentId}
          onClick={navigateToPreview}
          data-testid="insights-preview-button"
          sx={{
            border: '1px solid #00283A',
            '&:hover': {
              backgroundColor: 'rgba(0, 40, 58, 0.04)',
              color: '#00283ADE',
            },
            '&:disabled': {
              border: '1px solid rgba(0, 40, 58, 0.38)',
              background: 'rgba(0, 40, 58, 0.12)',
            },
          }}
        >
          <IcoInsightsPreview />
          {isBig && <Typography variant="labelMedium">{t('preview')}</Typography>}
        </IconButtonComponent>
      </span>
    </Tooltip>
  );
}
