import { ColumnTypeIdentifier, RowTypesIdentifier, TableRowType } from '../../../grid/reduxStore/table.types';
import { isRowBelongsToTotal } from '../../../../../muiTheme/dataGridUtils';

export const roundDecimalValue = (inputValue: string | number, allowedDecimalPlaces: number): string => {
  const numericValue = parseFloat(inputValue as string);
  const decimalPart = (numericValue.toString().split('.')[1] || '').length;

  if (decimalPart > allowedDecimalPlaces) {
    return numericValue.toFixed(allowedDecimalPlaces);
  }
  return numericValue.toString();
};

export function isPricingNumberCell(row: TableRowType, columnType: ColumnTypeIdentifier): boolean {
  if (row.rowType !== RowTypesIdentifier.BODY) return false;
  return [ColumnTypeIdentifier.PRICE, ColumnTypeIdentifier.SUBTOTAL].includes(columnType);
}

type GetPricingTotalsAndTextRowsSegregatedType = (allRows: TableRowType[]) => { textRows: TableRowType[]; totalRows: TableRowType[] };
export const getPricingTotalsAndTextRowsSegregated: GetPricingTotalsAndTextRowsSegregatedType = (allRows) => {
  const textRows: TableRowType[] = [];
  const totalRows: TableRowType[] = [];

  for (const row of allRows) {
    if (isRowBelongsToTotal(row)) {
      totalRows.push(row);
      continue;
    }
    textRows.push(row);
  }

  return { textRows, totalRows };
};
