import { useLocalStorage } from '../utils/useLocalStorage';

export function useShowExactTime() {
  const [showExactTime, setShowExactTime] = useLocalStorage('show-exact-time', false);

  const toggleShowExactTime = () => {
    setShowExactTime((prev) => !prev);
  };

  return { showExactTime, toggleShowExactTime };
}
