import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ContentNotFound from './content-not-found.png';

type ContentNotAvailableProps = {
  isExpanded?: boolean;
};

export function ContentNotAvailable({ isExpanded }: ContentNotAvailableProps) {
  const { t } = useTranslation();

  return (
    <Box
      data-testid="insights-content-not-available"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      padding={isExpanded ? 8 : 4}
      bgcolor={isExpanded ? 'primary.main' : 'background.paper'}
    >
      <Box marginBottom={2}>
        <img src={ContentNotFound} />
      </Box>
      <Typography variant="h6" textAlign="center" color={isExpanded ? 'white' : 'inherit'}>
        {t('pipeline.insights.content_not_available_title')}
      </Typography>
      <Typography variant="body1" textAlign="center" color={isExpanded ? 'white' : 'inherit'}>
        {t('pipeline.insights.content_not_available_description')}
      </Typography>
    </Box>
  );
}
