import { useQuery } from 'react-query';
import { apiDocumentRepository } from 'services/documents/ApiDocumentRepository';
import { QueryKeys } from 'utils/queryKeys';

export const useGetDocumentByIdQuery = (documentId: string) => {
  return useQuery({
    queryKey: QueryKeys.getDocumentById(documentId),
    queryFn: () => apiDocumentRepository.getDocumentById(documentId),
    enabled: !!documentId,
  });
};
