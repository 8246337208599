import { SxProps, Theme } from '@mui/material';
import { useRef } from 'react';
import { Document } from 'services/documents/Document';
import { DocumentsList as BaseDocumentsList } from '../components/documents-list/DocumentsList';
import { highlight, Higlighter } from '../components/Highlighter';
import { Insights } from '../insights';
import { DocumentListItem } from './DocumentListItem';

type DocumentsListProps = {
  documents: Document[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
  refreshDocumentList: () => Promise<void>;
  sx: SxProps<Theme>;
};

export function DocumentsList({ documents, refreshDocumentList, setCurrentPage, sx }: DocumentsListProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const viewCreatedDocument = (documentId: string) => {
    setCurrentPage(1);
    scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    highlight(documentId);
  };

  return (
    <BaseDocumentsList dataTestId="documents-list" sx={sx} ref={scrollRef}>
      {documents.map((document) => (
        <Higlighter key={document.id} id={document.id}>
          <DocumentListItem document={document} refreshDocumentList={refreshDocumentList} viewCreatedDocument={viewCreatedDocument} />
        </Higlighter>
      ))}
      <Insights />
    </BaseDocumentsList>
  );
}
