import { commanSeparatedNumber } from 'components/editor/hooks/useFieldValidator';
import { roundDecimalValue } from '../helper';
import { ColumnTypeIdentifier } from 'components/editor/grid/reduxStore/table.types';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { CustomTableCell, CustomTableCellInPreview } from '../components/CustomTableCell';
import { cellMenu } from '../defaultData';
import { isTaxOrDiscountRow } from 'muiTheme/dataGridUtils';

interface TableNumericCellProps {
  params: GridCellParams;
  columnType: ColumnTypeIdentifier;
  pricingDecimalPlaces: number;
  isDocumentLocked?: boolean;
}

export const PRICING_COLUMNS = [ColumnTypeIdentifier.PRICE, ColumnTypeIdentifier.SUBTOTAL];

export const TableNumericCell = ({ params, columnType, pricingDecimalPlaces, isDocumentLocked }: TableNumericCellProps) => {
  const { row, colDef } = params;
  const formatterKey = colDef?.['columnType'].toLowerCase();
  const value = params?.value as string | number;
  const isCustomFormatterApplies = !PRICING_COLUMNS.includes(columnType) || !Number(value) || isTaxOrDiscountRow(row);
  const TableCellComponent = isDocumentLocked ? CustomTableCellInPreview : CustomTableCell;

  if (!params) return <></>;

  if (!params?.value) {
    return <TableCellComponent params={params} value="" />;
  }

  if (isCustomFormatterApplies) {
    const valueFormatter = row.cellConfig?.[formatterKey]?.valueFormatter || '';
    const displayedValue = `${valueFormatter === cellMenu[0].sign ? `${valueFormatter + '' + value}` : `${value + '' + valueFormatter}`}`;
    return <TableCellComponent params={params} value={displayedValue} />;
  }

  const formattedValue = `$${commanSeparatedNumber(Number(roundDecimalValue(value, pricingDecimalPlaces)))}`;
  return <TableCellComponent params={params} value={formattedValue} />;
};

export const getIsCellEditingAllowed = ({ row, colDef }: { row: GridRowModel; colDef: GridColDef }): boolean => {
  if (!row?.cellConfig || !row?.cellConfig[colDef.field]) {
    return false;
  } else {
    return row.cellConfig[colDef.field]?.isEditingActive;
  }
};
