import { Box, ListItemText, Typography } from '@mui/material';
import { BetaChip } from '../BetaChip';

export function DocumentTitle({ title, subtitle, isV2 }: { title: string; subtitle: string; isV2: boolean }) {
  return (
    <ListItemText
      sx={{ flex: '1 1 150px' }}
      primary={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!isV2 && <BetaChip />}
          <Typography
            variant="body1"
            fontWeight={600}
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            data-testid="document-title"
          >
            {title}
          </Typography>
        </Box>
      }
      secondary={
        <Typography variant="body2" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
          {subtitle}
        </Typography>
      }
    />
  );
}
