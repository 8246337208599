import { Check, MoreVert } from '@mui/icons-material';
import { CircularProgress, Divider, IconButton, ListItem, Menu, MenuItem, Tooltip, Typography, useTheme } from '@mui/material';
import { openNotification } from 'components/notification';
import { openToast } from 'components/toast';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document } from 'services/documents/Document';
import { useManuallyUpdateDocumentStatus } from './useManuallyUpdateDocumentStatus';
import { useVoidDocument } from './useVoidDocument';
import { VoidDocumentModal } from './VoidDocumentModal';

const statusFlowOrder = ['Draft', 'Sent', 'Awaiting Signature', 'Won', 'Lost'];

export type MoreDocumentActionProps = {
  documentId: string;
  documentStatus: Document['status'];
  documentTitle: string;
  refreshDocumentList: () => void;
  onMenuClose: () => void;
};

export function MoreDocumentAction({
  documentId,
  documentStatus,
  documentTitle,
  refreshDocumentList,
  onMenuClose,
}: MoreDocumentActionProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isVoidDialogOpen, setIsVoidDialogOpen] = useState(false);
  const menuAnchorRef = useRef<HTMLButtonElement>(null);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setIsMenuOpen(true);
    event.stopPropagation();
    event.preventDefault();
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    onMenuClose();
  };

  const {
    availableStatuses,
    updateDocumentStatus,
    isLoading: isDocumentStatusLoading,
    canEditDocumentStatus,
  } = useManuallyUpdateDocumentStatus({
    documentId,
    currentDocumentStatus: documentStatus,
    onUpdateSuccess: (newStatus: string) => {
      openToast({
        message: t('pipeline.tabs.document_list.more_actions.update_status_success_message_title', {
          documentName: documentTitle,
          newStatus: t(`pipeline.tabs.document_list.${newStatus}`).toLowerCase(),
        }),
      });
      closeMenu();
      refreshDocumentList();
    },
    onUpdateFailure: async () => {
      openNotification({
        type: 'error',
        title: t('pipeline.tabs.document_list.more_actions.update_status_error_message_title'),
        description: (
          <>
            {t('pipeline.tabs.document_list.more_actions.update_status_error_message_part_1')}
            <a href="mailto:support@proposify.com">{t('pipeline.tabs.document_list.more_actions.update_status_error_message_part_2')}</a>
          </>
        ),
      });
    },
  });

  const destinationStatuses = [...availableStatuses, ...(['Sent', 'Draft'].includes(documentStatus) ? [documentStatus] : [])].sort(
    (a, b) => statusFlowOrder.indexOf(a) - statusFlowOrder.indexOf(b)
  );

  const { isVoidable, voidDocument } = useVoidDocument({
    documentId,
    documentStatus,
    onVoidSuccess: () => {
      openToast({
        message: t('pipeline.tabs.document_list.more_actions.void_document_success_message', {
          documentName: documentTitle,
        }),
      });
      refreshDocumentList();
    },
    onVoidFailure: async () => {
      openNotification({
        type: 'error',
        title: t('pipeline.tabs.document_list.more_actions.void_document_error_message_title'),
        description: (
          <>
            {t('pipeline.tabs.document_list.more_actions.void_document_error_message_part_1')}
            <a href="mailto:support@proposify.com">{t('pipeline.tabs.document_list.more_actions.void_document_error_message_part_2')}</a>
          </>
        ),
      });
    },
  });

  const onCancelVoidDocument = () => {
    closeMenu();
    setIsVoidDialogOpen(false);
  };

  const onConfirmVoidDocument = () => {
    closeMenu();
    setIsVoidDialogOpen(false);
    voidDocument();
  };

  return (
    <>
      <Tooltip title={t('pipeline.tabs.document_list.more_actions.open_more_actions_tooltip')}>
        <IconButton ref={menuAnchorRef} onClick={openMenu} data-testid="show-more-button">
          <MoreVert color="primary" />
        </IconButton>
      </Tooltip>
      <Menu
        data-testid="status-menu"
        anchorEl={menuAnchorRef.current}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          borderRadius: theme.shape.borderRadius,
        }}
      >
        {canEditDocumentStatus && (
          <ListItem dense>
            <Typography variant="subtitle2" color={theme.palette.text.secondary}>
              {t('pipeline.tabs.document_list.more_actions.update_status')}
            </Typography>
            <CircularProgress
              size="1em"
              sx={{ visibility: isDocumentStatusLoading ? 'visible' : 'hidden', marginLeft: theme.spacing(1.5) }}
            />
          </ListItem>
        )}
        {canEditDocumentStatus &&
          destinationStatuses.map((status) => (
            <MenuItem
              dense
              onClick={updateDocumentStatus(status)}
              disabled={status === documentStatus || isDocumentStatusLoading}
              key={status}
            >
              <Check sx={{ visibility: status === documentStatus ? 'visible' : 'hidden', marginRight: theme.spacing(1.5) }} />
              <Typography variant="body1" color="primary">
                {t(`pipeline.tabs.document_list.${status.toLowerCase()}`)}
              </Typography>
            </MenuItem>
          ))}
        {isVoidable && canEditDocumentStatus && <Divider />}
        {isVoidable && (
          <MenuItem dense onClick={() => setIsVoidDialogOpen(true)} disabled={isDocumentStatusLoading}>
            <Typography variant="body1" color="primary" sx={{ paddingRight: theme.spacing(8) }}>
              {t('pipeline.tabs.document_list.more_actions.void_document')}
            </Typography>
          </MenuItem>
        )}
      </Menu>
      {isVoidable && (
        <VoidDocumentModal voidDialogOpen={isVoidDialogOpen} onConfirmed={onConfirmVoidDocument} onClose={onCancelVoidDocument} />
      )}
    </>
  );
}
