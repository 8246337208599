import { Card, CardContent, CardHeader } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ViewedSectionsChartPresenter } from './ViewedSectionsChartPresenter';
import { ViewedSectionsChartSkeleton } from './ViewedSectionsChartSkeleton';
import { useQueryViewedSections } from './useQueryViewedSections';

export function ViewedSectionsChart() {
  const { t } = useTranslation();
  const { viewedSections, isLoading } = useQueryViewedSections();

  return (
    <Card elevation={0}>
      <CardHeader
        title={t('pipeline.insights.analytics_tab_content.top_five_viewed_sections')}
        titleTypographyProps={{ variant: 'h6' }}
        data-testid="viewed-sections-chart-title"
      />
      <CardContent>
        {isLoading ? (
          <ViewedSectionsChartSkeleton />
        ) : (
          <ViewedSectionsChartPresenter viewedSections={viewedSections} data-testid="viewed-sections-chart-presenter" />
        )}
      </CardContent>
    </Card>
  );
}
