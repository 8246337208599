import { GridProps, styled } from '@mui/material';
import { MouseEventHandler, ReactNode } from 'react';
import { Grid } from '@mui/material';
import { COLORS, SIZES, SPACING } from 'muiTheme/MuiDataGrid';

const pseudoElement = {
  content: "''",
  display: 'block',
  position: 'absolute',
  width: '100%',
  height: '1px',
  marginRight: '-10px',
};

const FooterRowWrapper = styled(Grid)<{ isEditState?: boolean }>(({ theme }) => ({
  fontWeight: 600,
  columnSpacing: 2,
  minHeight: SIZES.defaultRowHeight,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: COLORS.subtotalTaxDiscountBg,
  color: COLORS.subtotalTaxDiscount,
  paddingRight: '10px',
  position: 'relative',
  '&.py-hide-total-row, &.py-hide-total-row:hover': {
    backgroundColor: COLORS.greyedBackgroundColor,
  },
  '&:last-child': {
    backgroundColor: COLORS.footerHeader,
    borderBottom: 0,
    color: theme.palette.primary.contrastText,
  },
  'div[data-testid^="title-"]': {
    paddingRight: SPACING.sm,
  },
  '&.selected-row, &.selected-row + .unselected-row': {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.30), 0px 1px 3px rgba(0, 0, 0, 0.15)',
  },
  '&.selected-row': {
    '&::before': {
      ...pseudoElement,
      top: 0,
    },
    '&::after': {
      ...pseudoElement,
      bottom: 0,
    },
    '&:hover::before, &:hover::after': {
      backgroundColor: `${COLORS.HoverNFocus}`,
    },
    'div[data-testid="input-tax"], div[data-testid="input-discount"]': {
      '&::before': {
        ...pseudoElement,
        backgroundColor: `${COLORS.HoverNFocus}`,
        top: 0,
      },
      '&::after': {
        ...pseudoElement,
        backgroundColor: `${COLORS.HoverNFocus}`,
        bottom: 1,
      },
      borderRight: `1px solid ${COLORS.HoverNFocus}`,
      borderLeft: `1px solid ${COLORS.HoverNFocus}`,
      position: 'relative',
    },
  },
  '&::before, &::after': {
    ...pseudoElement,
  },
  '&:hover::before, &:hover::after': {
    backgroundColor: COLORS.HoverNFocus,
  },
  '&::before': {
    top: 0,
  },
  '&::after': {
    bottom: 0,
    backgroundColor: 'rgba(224, 229, 231, 1)',
  },
}));

const FooterRowItem = ({
  children,
  testId,
  onClick,
  className,
  ...props
}: {
  children?: ReactNode;
  testId?: string;
  className?: string;
  onClick?: MouseEventHandler;
} & GridProps) => (
  <Grid item lineHeight="52px" md={3} textAlign="right" {...props} data-testid={testId} onClick={onClick} className={className}>
    {children}
  </Grid>
);

const PricingTableTotalFooterRow = ({
  title,
  value,
  onClick,
  isRowSelected,
  isEditState,
  children,
}: {
  title: string;
  value: string;
  isRowSelected?: boolean;
  isEditState?: boolean;
  onClick?: MouseEventHandler;
  children?: ReactNode;
}) => {
  const formatValue = (val: string | undefined) => (val ? `$${val}` : '');
  const testIdValue = title.toLowerCase();

  return (
    <FooterRowWrapper
      className={`${isRowSelected ? 'selected-row' : 'unselected-row'} py-total-row`}
      container
      data-testid={`${title.toLowerCase()}-row`}
      onClick={onClick}
      isEditState={isEditState}
    >
      <FooterRowItem />
      <FooterRowItem testId={`title-${testIdValue}`}>
        <span>{title}</span>
      </FooterRowItem>
      <FooterRowItem testId={`input-${testIdValue}`}>{children}</FooterRowItem>
      <FooterRowItem testId={`value-${testIdValue}`}>
        <span>{formatValue(value)}</span>
      </FooterRowItem>
    </FooterRowWrapper>
  );
};

export default PricingTableTotalFooterRow;
